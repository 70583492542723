// https://codesandbox.io/s/github/tannerlinsley/react-table/tree/v6/examples/editable-content?file=/src/index.js
import React from "react";

import styles from  '../Projects/Projects.module.css';
import { Row, Col, Alert, Button } from "reactstrap";
import {MdRemoveCircleOutline, MdAddCircleOutline} from "react-icons/md";
import ReactTable from "react-table";
import api from "../../api";
import { useHistory } from 'react-router-dom';

import "react-table/react-table.css";
import "react-table-hoc-fixed-columns/lib/styles.css";

const addButtonHeader = (addRow) => {
  const _click = () => {
      addRow();
  }
  return (
      <button type="button" onClick={_click} className="btn btn-default btn-sm">
          <MdAddCircleOutline size='1.3em'/>
      </button>
  );
};

const removeButtonCell = (props, removeRow) => {
  const _click = () => {
    removeRow(props.value);
  }
  return (
      <button type="button" onClick={_click} className="btn btn-default btn-sm">
          <MdRemoveCircleOutline size='1.3em'/>
      </button>
  );
};

const headerStyle = {fontWeight: 'bold', marginTop: 5};
const columnDefs = (addRow, removeRow, renderEditable) => [
  {
    Header: addButtonHeader(addRow),
    accessor: 'Id',
    minWidth: 50,
    Cell: props => removeButtonCell(props, removeRow),
    filterable: false,
    sortable: false,
    fixed: "left",
  },
  {Header: 'Consultant', accessor: 'CdgConsultantName', minWidth: 200, Cell: renderEditable, headerStyle},
  {Header: 'Daily Rate [€]', accessor: 'CdgConsultantDailyRate', minWidth: 100,filterable: false, Cell: renderEditable, headerStyle},
];


function ProjectConsultantsComponent(props) {
  const history = useHistory();

  const [data, setData] = React.useState(props.data)
  const [alert, setAlert] = React.useState({
    messge: "",
    visible: false,
    color: "warning",
  });

  const onAlertDismiss = React.useCallback(()=> {
    setAlert({
      messge: "",
      visible: false,
    });
  },[alert])

  const onAlertOpen = React.useCallback((message, color = "warning") =>{
    setAlert({
      messge: message,
      visible: true,
      color,
    });
  },[alert])

  React.useEffect(()=>{
    setData(props.data)
  },[props.data])

  if (data?.lenght === 0) {
    return null
  }

  const save = async (newData) => {
    try {
      if (newData.Id) await api.updateProjectConsultant(newData);
      else await api.addProjectConsultant(newData);
      props.refetch();
    } catch (error) {
      console.error(error);
      onAlertOpen("Can't update consultant");
    }
  }

  // const remove = async (id) => {
  //   try {
  //     await api.removeProjectConsultant(id);
  //   } catch (error) {
  //     console.error(error);
  //     onAlertOpen("Can't remove consultant");
  //   }
  // }

  const addRow = () => {
    const emptyRow = data.find(x => x.Id === null);
    if(emptyRow) return;
    const newData = [...data];
    newData.push({ Id: null, CdgProjectId: props.project.Id, CdgConsultantName: '', CdgConsultantDailyRate: 0 });
    setData(newData);
  }

  async function removeRow(id) {
    try {
      if (id) {
        await api.removeProjectConsultant(id);
        props.refetch();
      }
      const newData = data.filter(row => row.Id !== id);
      setData(newData);
    } catch (error) {
      onAlertOpen("Can't remove consultant");
    }
  }

  
const renderEditable = (cellInfo) => {
  return (
    <div
      style={{ backgroundColor: "#fafafa", marginTop: '10px' }}
      contentEditable
      suppressContentEditableWarning
      onBlur={e => {
        const newData = [...data];
        newData[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
        if (newData[cellInfo.index].CdgConsultantName !== '' && newData[cellInfo.index].CdgConsultantDailyRate !== 0) {
          save(newData[cellInfo.index]);
        }
        setData(newData);
      }}
      dangerouslySetInnerHTML={{
        __html: data[cellInfo.index][cellInfo.column.id]
      }}
    />
  );
}

  return (
    <>
      <Alert
          className="sticky-top pb-2"
          color={alert.color}
          isOpen={alert.visible}
          toggle={onAlertDismiss}
        >
          <h6>{alert.messge}</h6>
      </Alert>
      <Row className={styles.title}>
      <Col md={10}>
        <h3 className={styles.header}>
          <i className="fa fa-tasks"></i>
          <span className={styles.text}>{`${props.project.CdgProjectCodeExternalReferenceId} ${props.project.CdgProjectName} - External Consultants`}</span>
        </h3>
      </Col>
      <Col>
          <Button color="secondary" onClick={history.goBack} >&nbsp;Back&nbsp;</Button>
      </Col>
    </Row>
      <Row>
        <Col md={{offset: 2, size: 8}}>
          <ReactTable
            columns={columnDefs(addRow, removeRow, renderEditable)}
            data={data}
            defaultPageSize={10}
            className="-striped -highlight"
            style={{ width: "100%", overflow: "auto" }}
          />
        </Col>
      </Row>
    </>
  );
}

export default React.memo(ProjectConsultantsComponent);
