import { getStatusDesc } from '../../helpers';
import moment from 'moment';
import NumberFormat from 'react-number-format';

const numberCell = row => <NumberFormat thousandSeparator={'.'} prefix={'€ '} decimalSeparator={','} value={row.value} displayType={'text'} disabled />

const headerStyle = {fontWeight: 'bold'};
const leftAlign = row => <div style={{textAlign: 'left'}}>{row.value}</div>

const columnDefs = [
  {
    Header: "Status",
    accessor: "Status",
    fixed: "left",
    minWidth: 90,
    filter: 'includes',
    Cell: row => getStatusDesc(row.value)
  },
  {
    Header: "Client",
    accessor: "ClientName",
    fixed: "left",
    minWidth: 140,
    filter: 'includes',
  },
  {
    Header: "Code",
    accessor: "ProjectCode",
    fixed: "left",
    minWidth: 110,
    filter: 'includes',
  },
  {
    Header: "Project Name",
    accessor: "ProjectName",
    fixed: "left",
    minWidth: 150,
    Cell: row => leftAlign(row),
    filter: 'includes',
  },
  {
    Header: "Project Manager",
    accessor: "ProjectManagerFullName",
    fixed: "left",
    minWidth: 150,
    Cell: row => leftAlign(row),
    filter: 'includes',
  },
  {
    Header: "Start Date",
    accessor: "StartDate",
    minWidth: 80,
    fixed: "left",
    Cell: row => moment(row.value).format('DD-MM-YYYY')
  },
  {
    Header: "End Date",
    accessor: "EndDate",
    minWidth: 80,
    fixed: "left",
    Cell: row => moment(row.value).format('DD-MM-YYYY')
  },
  {
    Header: "Selling Price [€]",
    accessor: "SellingPrice",
    minWidth: 120,
    filterable: false, 
    Cell: row => numberCell(row)
  },
  {
    Header: "Planned [€]",
    accessor: "Planned",
    minWidth: 130,
    filterable: false,
  },
  {
    Header: "Charged [€]",
    accessor: "Charged",
    minWidth: 130,
    filterable: false,
  },
  {
    Header: "Backlog [€]",
    accessor: "Backlog",
    minWidth: 130,
    filterable: false,
  },
  {
    Header: "WIP [€]",
    accessor: "WIP",
    minWidth: 130,
    filterable: false,
  },
];

export const columns = columnDefs.map(col => ({...col, headerStyle }));