import axios from 'axios';

const apiConfig = {
    development: 'http://localhost:8000',
    // development: 'https://gbsw8yypff.execute-api.eu-west-1.amazonaws.com/test',
    // development: 'https://23w3nvv46f.execute-api.eu-west-1.amazonaws.com/prod',
    // production: 'https://gbsw8yypff.execute-api.eu-west-1.amazonaws.com/test',
    production: 'https://23w3nvv46f.execute-api.eu-west-1.amazonaws.com/prod',
};

const env = process.env.NODE_ENV || 'development';
const baseURL = apiConfig[env];
// https://stackoverflow.com/questions/48865705/store-not-updating-in-ie11-react-redux/48867780#48867780
const instance = axios.create({baseURL: `${baseURL}/api`, headers: {Pragma: "no-cache"} });

const authenticate = (email, password) => {
    const url = '/user/authenticate';
    return instance.post(url, {username: email, password});
};

const changePassword = (oldPassword, newPassword) => {
    const url = '/user/changepassword';
    return instance.post(url, {oldPassword, newPassword});
};

const getProjects = () => {
    const url = '/projects';
    return instance.get(url);
}

const getPractices = () => {
    const url = '/practices';
    return instance.get(url);
}

const getCategories = () => {
    const url = '/categories';
    return instance.get(url);
}

const getLastUpdate = () => {
    const url = '/lastupdate';
    return instance.get(url);
}

const getUnassignedCodes = () => {
    const url = `/codes/unassigned`;
    return instance.get(url);
}

const getCurrentCode = id => {
    const url = `/codes/current/${id}`;
    return instance.get(url);
}

const getProjectDetail = id => {
    const url = `/project/${id}`;
    return instance.get(url);
}

const getProjectWorkforce = id => {
    const url = `/projectworkforce/${id}`;
    return instance.get(url);
}

const getWorkforce = id => {
    const url = `/workforce/${id}`;
    return instance.get(url);
}

const getManagers = (id) => {
    const url = `/managers/${id}`;
    return instance.get(url);
}

const addProject = (project) => {
    const url = `/project`;
    return instance.post(url, project);
}

const updateProject = (project) => {
    const url = `/project/${project.Id}`;
    return instance.put(url, project);
}
const getAllocations = () => {
    const url = `/resource/allocation`;
    return instance.get(url);
}

const addWorkForce = (workforce) => {
    const url = `/projectworkforce`;
    return instance.post(url, workforce);
}

const updateWorkForce = (workforce) => {
    const url = `/projectworkforce/${workforce.Id}`;
    return instance.put(url, workforce);
}

const removeWorkForce = (id) => {
    const url = `/projectworkforce/${id}`;
    return instance.delete(url);
}

const submitProject = (id) => {
    const url = `/submit/${id}`;
    return instance.post(url);
}

const getDownloadData = (type, scope) => {
    const url = `/download/${type}/${scope}`;
    return instance.get(url);
}

const getProjectConsultants = (id) => {
    const url = `/consultants/${id}`;
    return instance.get(url);
}

const addProjectConsultant = ({CdgProjectId, CdgConsultantName, CdgConsultantDailyRate}) => {
    const url = `/consultant/${CdgProjectId}`;
    return instance.post(url, {CdgConsultantName, CdgConsultantDailyRate});
};

const updateProjectConsultant = ({Id, CdgWorkforceId, CdgConsultantName, CdgConsultantDailyRate}) => {
    const url = `/consultant/${Id}`;
    return instance.put(url, {CdgWorkforceId, CdgConsultantName, CdgConsultantDailyRate});
};

const removeProjectConsultant = (Id) => {
    const url = `/consultant/${Id}`;
    return instance.delete(url);
};

const getDummyProjects = () => {
    const url = `/dummyprojects`;
    return instance.get(url);
}

const reconcileProject = (dummyId, code) => {
    const url = `/dummyproject/${dummyId}`;
    return instance.put(url, {code});
};

const getAreaRevenues = id => {
    const url = `/revenues/area${id ? `/${id}` : ''}`;
    return instance.get(url);
};


const getAreaResources = id => {
    const url = `/resource/allocations${id ? `/${id}` : ''}`;
    return instance.get(url);
};

const updateResourceAllocation = ({workforceId, ...rest}) => {
    const url = `/resource/allocations/${workforceId}`;
    return instance.put(url, { ...rest });
};

const getReportProjectList = () => {
    const url = `/reports/projects/list`;
    return instance.get(url);
};

const getAreaResourceTime = () => {
    const url = `/reports/resource/time`;
    return instance.get(url);
};

const getWriteData = fileName => {
    const url = `/file/write`;
    return instance.post(url, { fileName } );
};

const getImportStatus = () => {
    const url = `/import/status`;
    return instance.get(url);
};

const exportData = (exportName) => {
    const url = `/export/${exportName}`;
    return instance.get(url);
};

const importFile = (importType, fileName) => {
    const url = `/import/start`;
    return instance.post(url, { importType, fileName } );
};

const uploadFileAws =  async ({url, fields}, dataToLoad) => {
    if(url && fields) {
        // delete auth header otherwise AWS complains
        const authHeader = axios.defaults.headers.common.Authorization;
        delete axios.defaults.headers.common.Authorization;
        const formData = new FormData();
        Object.keys(fields).forEach(key => {
            formData.append(key, fields[key]);
        });
        formData.append('file', dataToLoad);    
        await axios.post(url, formData);
        // restore auth header
        axios.defaults.headers.common.Authorization = authHeader;
    } else {
        console.error('No url or fields');
        throw new Error('No url or fields');
    }
};
  

const api = {
    getAllocations,
    authenticate,
    changePassword,
    getProjects,
    addProject,
    updateProject,
    getPractices,
    getCategories,
    getLastUpdate,
    getUnassignedCodes,
    getCurrentCode,
    getProjectDetail,
    getProjectWorkforce,
    getWorkforce,
    getManagers,
    addWorkForce,
    updateWorkForce,
    removeWorkForce,
    submitProject,
    getDownloadData,
    getProjectConsultants,
    addProjectConsultant,
    updateProjectConsultant,
    removeProjectConsultant,
    getDummyProjects,
    reconcileProject,
    getAreaRevenues,
    getAreaResources,
    updateResourceAllocation,
    getReportProjectList,
    getAreaResourceTime,
    getWriteData,
    getImportStatus,
    exportData,
    importFile,
    uploadFileAws
};

export default api;
