import React, { useEffect } from "react";
import { connect } from "react-redux";
import ImportExportComponent from "./ImportExportComponent";
import { Container } from "reactstrap";
import { getImportStatus } from "../../reducers";
import { setColumns } from "./utils";

const columns = setColumns();

function ImportExportContainer({ importExport, getImportStatus }) {
  useEffect(() => {
    const interval = setInterval(getImportStatus, 5000);
    getImportStatus();
    return () => clearInterval(interval);
  }, [getImportStatus]);

  const getStatusData = () => {
    const tableData = importExport.data?.rows || [];
    return tableData;
  }

  if(!importExport) return null;
  return (
    <Container fluid>
      <ImportExportComponent 
          data={getStatusData()} 
          columns={columns}
          isLoading={importExport.fetching}
          fetched={importExport.fetched}
          batchid={importExport.data.batchid}
        />
      {importExport.errors && importExport.errors.length > 0 && (
        <p>{importExport.errors}</p>
      )}
    </Container>
  );
}

ImportExportContainer.defaultProps = {
  reportAreaResourceTime: [],
};

const mapStateToProps = ({ importExport }) => {
  return {
    importExport,
  };
};

const mapDispatchToProps = {
  getImportStatus,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportExportContainer);
