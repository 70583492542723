import React, { useEffect} from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getDummyProjects } from "../../reducers";
import { Container } from 'reactstrap';
import Loader from '../Loader';
import ProjectsReconciliationComponent from './ProjectsReconciliationComponent';

const ProjectsReconciliationContainer = (props) => {
  useEffect(() => props.getDummyProjects(), []);

  return (
    <Container fluid>
      {props.dummyProjects.fetched && 
      <ProjectsReconciliationComponent {...props} 
        data={props.dummyProjects.data}
      />}
      <Loader isLoading={props.dummyProjects.fetching} />
    </Container>
  );
};

const mapStateToProps = ({
  dummyProjects,
}) => {
  return {
    dummyProjects,
  };
};

const mapDispatchToProps = {
  getDummyProjects,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProjectsReconciliationContainer));
