import React from "react";

import styles from  '../Projects/Projects.module.css';
import { Row, Col } from "reactstrap";
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";

import "react-table/react-table.css";
import "react-table-hoc-fixed-columns/lib/styles.css";

const makeZeroNullVall = (data)=>{
  data.forEach(el=>{
    const keys = Object.keys(el)
    keys.forEach(key=>{
      el[key] = el[key] ?? 0
    })
  })
  return data;
}

const ReactTableFixedColumns = withFixedColumns(ReactTable);

function MyAllocationComponent(props) {
  const [data, setData] = React.useState(props.data)
  React.useEffect(()=>{
    setData(props.data)
  },[props.data])

  if (data?.lenght === 0) {
    return null
  }

  return (
      <>
    <Row className={styles.title}>
    <Col md={4}>
      <h3 className={styles.header}>
        <i className="fa fa-tasks"></i>
        <span className={styles.text}>Allocations List</span>
      </h3>
    </Col>
    {data && data.length > 1 && <>
        <Col className='textAllocation' md={2}>
          <span className="childSpan">{`Target Days: ${data[1].TargetDays}`}</span>
        </Col>
        <Col className='textAllocation' md={3}>
          <span className="childSpan">{`Total Charged Days: ${data[1].TotalChargedDays} (${data[1].ChargedPerc} %)`}</span>
        </Col> 
        {/* <Col className='textAllocation' md={2}>
          <span className="childSpan">Charged Percentage: {data[0].ChargedPerc}</span>
        </Col> */}
        <Col className='textAllocation' md={2}>
          <span className="childSpan">{`Total Planned: ${data[1].TotalPlanned} (${data[1].PercPlanned} %)`}</span>
        </Col>
    </>}
  </Row>
    <Row>
      <Col md={12}>
        <ReactTableFixedColumns
          columns={props.columns}
          data={makeZeroNullVall(data)}
          defaultPageSize={10}
          className="-striped -highlight"
          style={{ width: "100%", overflow: "auto" }}
        />
      </Col>
    </Row>
    </>
  );
}

export default React.memo(MyAllocationComponent);
