import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import AreaResourcesComponent from "./AreaResourcesComponent";
import { setTotalColumns, setDetailColumns } from "./utils";
import { Container } from "reactstrap";
import Loader from "../Loader";
import { getAreaResources } from "../../reducers";

const curMonth = parseInt(moment().format("M")) -1;
const curYear = parseInt(moment().format("YYYY"));

function AreaResourcesContainer({ areaResources, getAreaResources, match: { params: { id }} }) {
  const [userId, setUserId] = useState(id);
  useEffect(() => {
    const pId = id ? parseInt(id) : null;
    setUserId(pId);
    getAreaResources(pId);
  }, [getAreaResources, id]);

  const getAreaResourcesData = () => {
    const tableData = [...areaResources.data];
    return tableData;
  }

  if(!areaResources) return null;
  return (
    <Container fluid>
      {areaResources.fetched && (
        <AreaResourcesComponent 
          data={getAreaResourcesData()} 
          columns={userId ? setDetailColumns(curMonth) : setTotalColumns()} 
          isDetail={!!userId} 
          curMonth={curMonth}
          curYear={curYear}
          refetch={() => getAreaResources(userId)}
        />
      )}
      {areaResources.errors && areaResources.errors.length > 0 && (
        <p>{areaResources.errors}</p>
      )}
      <Loader isLoading={areaResources.fetching} />
    </Container>
  );
}

AreaResourcesContainer.defaultProps = {
  areaResources: [],
};

const mapStateToProps = ({ areaResources }) => {
  return {
    areaResources,
  };
};

const mapDispatchToProps = {
  getAreaResources,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AreaResourcesContainer));
