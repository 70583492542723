import api from '../api';

const API = 'consultants';
const PENDING = `${API}_PENDING`;
const FULFILLED = `${API}_FULFILLED`;
const REJECTED = `${API}_REJECTED`;

const initialState = {
  data: [],
  currentId: null,
  fetching: false,
  fetched: false,
  errors: []
};

const reducer = (state = initialState, action = {}) => {
  const { type, payload, meta } = action;
  switch (type) {
    case PENDING: return {...state, fetching: true, fetched: false};
    case REJECTED: return {...initialState, errors: payload};
    case FULFILLED: return { data: [...payload.data], currentId: meta, fetching: false, fetched: true};
    default: return state;
  }
}
export default reducer;

// Action Creator
export const getConsultants = id => ({
  type: API,
  payload: api.getProjectConsultants(id),
  meta: id
});
