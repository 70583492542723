import { Link } from "react-router-dom";
import { AREA_RESOURCE_DETAIL } from '../../navigation/routes';
import { startFilterMethod, includesFilterMethod } from '../../helpers/filters';

const headerStyle = { fontWeight: 'bold' };


const makeResourceLink = row => `${AREA_RESOURCE_DETAIL}/${row.original.Id}`;
const leftAlign = row => <div title={row.value} style={{textAlign: 'left'}}>{row.value}</div>

const mapper = cols => cols.map(col => ({...col, headerStyle }));


const COMMON_COLUMNS = [
  { Header: "Jan", accessor: "JanPlanDays", minWidth: 60, month: 0 },
  { Header: "Feb", accessor: "FebrPlanDays", minWidth: 60, month: 1 },
  { Header: "Mar", accessor: "MarchPlanDays", minWidth: 60, month: 2 },
  { Header: "Apr", accessor: "AprPlanDays", minWidth: 60, month: 3 },
  { Header: "May", accessor: "MayPlanDays", minWidth: 60, month: 4 },
  { Header: "Jun", accessor: "JunPlanDays", minWidth: 60, month: 5 },
  { Header: "Jul", accessor: "JulPlanDays", minWidth: 60, month: 6 },
  { Header: "Aug", accessor: "AugPlanDays", minWidth: 60, month: 7 },
  { Header: "Sep", accessor: "SeptPlanDays", minWidth: 60, month: 8 },
  { Header: "Oct", accessor: "OctPlanDays", minWidth: 60, month: 9 },
  { Header: "Nov", accessor: "NovPlanDays", minWidth: 60, month: 10 },
  { Header: "Dec", accessor: "DecPlanDays", minWidth: 60, month: 11 },
  { Header: "Jan Y+1", accessor: "JanY1PlanDays", minWidth: 60, month: 12 },
  { Header: "Feb Y+1", accessor: "FebrY1PlanDays", minWidth: 60, month: 13 },
  { Header: "Mar Y+1", accessor: "MarchY1PlanDays", minWidth: 60, month: 14 },
];

const LEFT_TOTAL_COLUMNS = [
  { Header: "Name", accessor: "Name", fixed: "left", minWidth: 120, Cell: row => <Link to={makeResourceLink(row)}>{leftAlign(row)}</Link>, filterable: true, filterMethod: includesFilterMethod },
  { Header: "Target Days", accessor: "TargetDays", fixed: "left", minWidth: 80, },
  { Header: "Tot. Charged Days", accessor: "TotalChargedDays", fixed: "left", minWidth: 120, },
  { Header: "Charged %", accessor: "ChargedPerc", fixed: "left", minWidth: 80, },
  { Header: "Tot. Planned Days", accessor: "TotalPlannedDays", fixed: "left", minWidth: 120, },
  { Header: "Planned %", accessor: "PercPlanned", fixed: "left", minWidth: 80, },
  { Header: "Project Code", accessor: "ProjectCode", fixed: "left", minWidth: 100, maxWidth: 300, Cell: row => leftAlign(row), filterable: true, filterMethod: startFilterMethod },
  { Header: "Project Name", accessor: "ProjectName", fixed: "left", minWidth: 200, maxWidth: 300, Cell: row => leftAlign(row), filterable: true, filterMethod: includesFilterMethod },
  { Header: "Project Mgr.", accessor: "ProjectManager", fixed: "left", minWidth: 120, maxWidth: 300, Cell: row => leftAlign(row), filterable: true, filterMethod: includesFilterMethod },
];

const LEFT_DETAIL_COLUMNS = [
  { Header: "Project Code", accessor: "ProjectCode", fixed: "left", minWidth: 100, maxWidth: 300, Cell: row => leftAlign(row), filterable: true, filterMethod: startFilterMethod },
  { Header: "Project Name", accessor: "ProjectName", fixed: "left", minWidth: 200, maxWidth: 300, Cell: row => leftAlign(row), filterable: true, filterMethod: includesFilterMethod },
  { Header: "Project Manager", accessor: "ProjectManager", fixed: "left", minWidth: 150, maxWidth: 300, Cell: row => leftAlign(row), filterable: true, filterMethod: includesFilterMethod },
  { Header: "Daily Rate", accessor: "CdgWorkforceDailyRate", fixed: "left", minWidth: 120, maxWidth: 300, filterable: true },
];

const TOTAL_COLUMNS = [...LEFT_TOTAL_COLUMNS, ...COMMON_COLUMNS];
export const setTotalColumns = () => mapper(TOTAL_COLUMNS);

export const setDetailColumns = month => {
  const filteredMonths = COMMON_COLUMNS.filter(col => col.month >= month - 1 && col.month <= month + 6);
  return mapper([...LEFT_DETAIL_COLUMNS, ...filteredMonths])
    .map(col => ({...col, headerStyle: {...col.headerStyle, backgroundColor: col.month === month ? '#FFFF8F' : undefined }}));
};
