import React, { useEffect, useState, memo } from "react";
import _ from "lodash";
import styles from "../Projects/Projects.module.css";
import { Row, Col } from "reactstrap";
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { getStatusDesc } from "../../helpers";
import FrontendComponentsMultipleSelection from "../Projects/projects-multiple-selection";
import "react-table/react-table.css";
import "react-table-hoc-fixed-columns/lib/styles.css";

const makeZeroNullVall = (data) => {
  if (data.length === 0) return data;
  data.forEach((el) => {
    const keys = Object.keys(el);
    keys.forEach((key) => {
      el[key] = el[key] ?? 0;
    });
  });
  return data;
};

const ReactTableFixedColumns = withFixedColumns(ReactTable);

function ReportProjectListComponent({ projects, columns }) {
  const [flag, setFlag] = useState();
  const [FilteredTableData, setFilteredTableData] = useState();
  const [selectedData, setSelectedData] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedClient, setSelectedClient] = useState();
  const [selectedCode, setSelectedCode] = useState();
  const [selectedName, setSelectedName] = useState();
  const [selectedManager, setSelectedManager] = useState();
  const [statusList, setStatusList] = useState();
  const [clientsList, setClientsList] = useState();
  const [codesList, setCodesList] = useState();
  const [projectNameList, setProjectNameList] = useState();
  const [projectManagerList, setProjectManagerList] = useState();

  useEffect(() => {
    let findMinArr;
    if (flag === "Status") {
      findMinArr = selectedStatus;
    }
    if (flag === "ClientName") {
      findMinArr = selectedClient;
    }
    if (flag === "ProjectCode") {
      findMinArr = selectedCode;
    }
    if (flag === "ProjectName") {
      findMinArr = selectedName;
    }
    if (flag === "ProjectManagerFullName") {
      findMinArr = selectedManager;
    }
    if (!findMinArr) {
      const minValue = _.min([
        selectedStatus?.length,
        selectedClient?.length,
        selectedCode?.length,
        selectedName?.length,
        selectedManager?.length,
      ]);
      findMinArr = [
        selectedStatus,
        selectedClient,
        selectedCode,
        selectedName,
        selectedManager,
      ].find((el, id) => {
        if (el?.length === minValue) {
          switch (id) {
            case 0:
              setFlag("Status");
              break;
            case 1:
              setFlag("ClientName");
              break;
            case 2:
              setFlag("ProjectCode");
              break;
            case 3:
              setFlag("ProjectName");
              break;
            default:
              setFlag("ProjectManagerFullName");
              break;
          }
        }
        return el?.length === minValue;
      });
    }
    setSelectedData(findMinArr);
    if (!selectedData || selectedData.length === projects.length) {
      setFilteredTableData([...projects]);
    } else {
      const Filtered = [];
      if (projects && projects.length > 0) {
        projects.forEach((d) => {
          const found = selectedData.find((s) => s === d[flag]);
          if (found) Filtered.push(d);
        });
      }
      setFilteredTableData([...Filtered]);
    }
  }, [
    projects,
    selectedData,
    selectedStatus,
    selectedClient,
    selectedCode,
    selectedName,
    selectedManager,
  ]);

  useEffect(() => {
    if (selectedData && selectedData.length > 0) {
      if (flag === "Status") {
        const optionsStatus = FilteredTableData.map((d) => ({
          id: d.Status,
          value: getStatusDesc(d.Status),
        }));
        setStatusList(optionsStatus);
      }
      if (flag === "ClientName") {
        const optionsClients = FilteredTableData.map((d) => ({
          id: d.ClientName,
          value: d.ClientName,
        }));
        setClientsList(optionsClients);
      }
      if (flag === "ProjectCode") {
        const optionsCodes = FilteredTableData.map((d) => ({
          id: d.ProjectCode,
          value: d.ProjectCode,
        }));
        setCodesList(optionsCodes);
      }
      if (flag === "ProjectName") {
        const optionsProjectName = FilteredTableData.map((d) => ({
          id: d.ProjectName,
          value: d.ProjectName,
        }));
        setProjectNameList(optionsProjectName);
      }
      if (flag === "ProjectManagerFullName") {
        const optionsProjectManager = FilteredTableData.map((d) => ({
          id: d.ProjectManagerFullName,
          value: d.ProjectManagerFullName,
        }));
        setProjectManagerList(optionsProjectManager);
      }
    } else {
      if (projects && projects.length > 0) {
        getAllOptions();
      }
    }
  }, [flag]);

  const getAllOptions = () => {
    const optionsStatus = projects.map((d) => ({
      id: d.Status,
      value: getStatusDesc(d.Status),
    }));
    setStatusList(optionsStatus);
    const optionsClients = projects.map((d) => ({
      id: d.ClientName,
      value: d.ClientName,
    }));
    setClientsList(optionsClients);
    const optionsCodes = projects.map((d) => ({
      id: d.ProjectCode,
      value: d.ProjectCode,
    }));
    setCodesList(optionsCodes);
    const optionsProjectName = projects.map((d) => ({
      id: d.ProjectName,
      value: d.ProjectName,
    }));
    setProjectNameList(optionsProjectName);
    const optionsProjectManager = projects.map((d) => ({
      id: d.ProjectManagerFullName,
      value: d.ProjectManagerFullName,
    }));
    setProjectManagerList(optionsProjectManager);
  };

  useEffect(() => {
    if (projects && projects.length > 0) {
      getAllOptions();
    } else {
      setStatusList([]);
      setClientsList([]);
      setCodesList([]);
      setProjectNameList([]);
      setProjectManagerList([]);
    }
  }, [projects]);

  const setFilters = (columns) =>
    columns.map((col) => {
      if (col.accessor === "Status") {
        return {
          ...col,
          Filter: (
            <FrontendComponentsMultipleSelection
              data={statusList}
              setFilter={(data) => {
                setFilteredTableData([]);
                setSelectedStatus(data);
              }}
              placeholder="Filter Projects"
              setFlag={setFlag}
              value={"Status"}
              setSelectedData={setSelectedStatus}
            />
          ),
        };
      }
      if (col.accessor === "ClientName") {
        return {
          ...col,
          Filter: (
            <FrontendComponentsMultipleSelection
              data={clientsList}
              setFilter={(data) => {
                setFilteredTableData([]);
                setSelectedClient(data);
              }}
              placeholder="Filter Projects"
              setFlag={setFlag}
              value={"ClientName"}
              setSelectedData={setSelectedClient}
            />
          ),
        };
      }
      if (col.accessor === "ProjectCode") {
        return {
          ...col,
          Filter: (
            <FrontendComponentsMultipleSelection
              data={codesList}
              setFilter={(data) => {
                setFilteredTableData([]);
                setSelectedCode(data);
              }}
              placeholder="Filter Projects"
              setFlag={setFlag}
              value={"ProjectCode"}
              setSelectedData={setSelectedCode}
            />
          ),
        };
      }
      if (col.accessor === "ProjectName") {
        return {
          ...col,
          Filter: (
            <FrontendComponentsMultipleSelection
              data={projectNameList}
              setFilter={(data) => {
                setFilteredTableData([]);
                setSelectedName(data);
              }}
              placeholder="Filter Projects"
              setFlag={setFlag}
              value={"ProjectName"}
              setSelectedData={setSelectedName}
            />
          ),
        };
      }
      if (col.accessor === "ProjectManagerFullName") {
        return {
          ...col,
          Filter: (
            <FrontendComponentsMultipleSelection
              data={projectManagerList}
              setFilter={(data) => {
                setFilteredTableData([]);
                setSelectedManager(data);
              }}
              placeholder="Filter Projects"
              setFlag={setFlag}
              value={"ProjectManagerFullName"}
              setSelectedData={setSelectedManager}
            />
          ),
        };
      }
      return col;
    });

  if (!FilteredTableData) return null;

  return (
    <>
      <Row className={styles.title}>
        <Col md={4}>
          <h3 className={styles.header}>
            <i className="fa fa-tasks"></i>
            <span className={styles.text}>Project List</span>
          </h3>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ReactTableFixedColumns
            columns={setFilters(columns)}
            data={makeZeroNullVall(FilteredTableData)}
            defaultPageSize={10}
            className="-striped -highlight"
            style={{ width: "100%", overflow: "auto" }}
            filterable={true}
          />
        </Col>
      </Row>
    </>
  );
}

export default memo(ReportProjectListComponent);
