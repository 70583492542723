import React from 'react';
import { Alert } from 'reactstrap';



//Aggiungi la sezione vuota

export const addSezione = arrSezioni => [{idSezione:99, nome:"Seleziona"}, ...arrSezioni];

export const addSottosezione = (arrSottosezione, idSezione) => [{id: 99, idSezione, desc:"Seleziona"}, ...arrSottosezione];

// map array to list for form select
export const renameObjectArrayProps = (objArr, [pId, pDesc]) => objArr.map(obj => ({ ...obj, id: obj[pId], desc: obj[pDesc] }));

//controllo dell'errore 
export const showErrorMsg = (risposta) => {
    if(risposta.response.status) 
        return (<Alert color="danger"> {risposta.messaggio + risposta.response.data.error } </Alert>);  
    else
        return null;
};

// lista stati progetti
export const PROJECT_STATUS_LIST = [
    {id: 'A', desc: 'Active'},
    {id: 'B', desc: 'Renew'},
    {id: 'C', desc: 'Closed'},
    {id: 'X', desc: 'Archived'},
];

export const getStatusDesc = id => PROJECT_STATUS_LIST.find(r => r.id === id).desc;

// lista colori Planned
export const PLANNED_COLORS = {
    0: 'grey',
    1: 'green',
    2: 'yellow',
    3: 'red',
    4: 'blue',
};

export const chargedColor = value => value < 1 ? 'green' : value > 1 ? 'red' : 'yellow';

export const toNumber = value => {
    if (!value) return 0;
    if (typeof value === 'number') return value;
    if (typeof value === 'string') {
        const n = Number(value.replace(',', '.').replace('<br>',''));
        return n ? n : 0;
    }
    return 0;
}