import React from 'react';
import { Route, Switch } from 'react-router-dom';

import * as routes from './routes';
import AuthRoute from './AuthRoute';
import LoginForm from '../components/LoginForm';
import Projects from '../components/Projects';
import Unauthorized from '../components/Unauthorized';
import ProjectDetail from '../components/ProjectDetail';
import ChangePasswordForm from '../components/ChangePasswordForm';
import MyAllocations from '../components/MyAllocation';
import ProjectConsultants from '../components/ProjectConsultants';
import ProjectsReconciliation from '../components/ProjectsReconciliation';
import ProjectsReconciliationDetailContainer from '../components/ProjectsReconciliationDetail';
import AreaRevenues from '../components/AreaRevenues';
import AreaResources from '../components/AreaResources';
import ReportProjectList from '../components/ReportProjectList';
import ReportAreaResourceTime from '../components/ReportAreaResourceTime';
import ImportExport from '../components/ImportExport';

export const AppRoutes = () =>
  <Switch>
    <AuthRoute exact path={routes.HOME} claim="authenticated" component={() => <Projects />} />
    <AuthRoute exact path={routes.CHANGEPASSWORD} claim="authenticated" component={() => <ChangePasswordForm />} />
    <AuthRoute exact path={routes.PROJECT_ADD} claim="authenticated" component={() => <ProjectDetail />} />
    <AuthRoute exact path={`${routes.PROJECT}/:id`} claim="authenticated" component={() => <ProjectDetail />} />
    <AuthRoute exact path={`${routes.CONSULTANTS}/:id`} claim="authenticated" component={() => <ProjectConsultants />} />
    <AuthRoute exact path={`${routes.AREA_REVENUES}`} claim="areaManager" component={() => <AreaRevenues/>} />
    <AuthRoute exact path={`${routes.AREA_DETAIL_REVENUES}/:id`} claim="areaManager" component={() => <AreaRevenues/>} />
    <AuthRoute exact path={routes.AREA_RESOURCES} claim="areaManager" component={() => <AreaResources/>} />
    <AuthRoute exact path={`${routes.AREA_RESOURCE_DETAIL}/:id`} claim="areaManager" component={() => <AreaResources/>} />
    <AuthRoute exact path={routes.ALLOCATIONS} claim="authenticated" component={() => <MyAllocations/>} />
    <AuthRoute exact path={routes.RECONCILIATION} claim="finance" component={() => <ProjectsReconciliation />} />
    <AuthRoute exact path={`${routes.RECONCILE_PROJECT}/:id`} claim="finance" component={() => <ProjectsReconciliationDetailContainer />} />
    <AuthRoute exact path={routes.REPORT_PROJECT_LIST} claim="authenticated" component={() => <ReportProjectList/>} />
    <AuthRoute exact path={routes.REPORT_AREA_RESOURCE_TIME} claim="authenticated" component={() => <ReportAreaResourceTime/>} />
    <AuthRoute exact path={routes.IMPORT} claim="importAdmin" component={() => <ImportExport/>} />
    <Route exact path={routes.LOGIN} component={() => <LoginForm />} />
    <Route exact path={routes.UNAUTHORIZED} component={() => <Unauthorized />} />
  </Switch>

export default AppRoutes;
