import React, { useEffect} from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getConsultants } from "../../reducers";
import { Container } from 'reactstrap';
import Loader from '../Loader';
import ProjectConsultantsComponent from './ProjectConsultantsComponent';

const ConsultantsContainer = (props) => {
  useEffect(() => {
    const id = props.match.params.id ? parseInt(props.match.params.id) : null;
    if(id) props.getConsultants(id);

  }, []);

  return (
    <Container fluid>
      {props.consultants.fetched && 
      <ProjectConsultantsComponent {...props} 
        project={props.project.data} 
        data={props.consultants.data}
        refetch={() => props.getConsultants(parseInt(props.match.params.id))}
      />}
      <Loader isLoading={props.consultants.fetching} />
    </Container>
  );
};

const mapStateToProps = ({
  consultants,
  projectDetail
}) => {
  return {
    consultants,
    project: projectDetail
  };
};

const mapDispatchToProps = {
  getConsultants,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ConsultantsContainer));
