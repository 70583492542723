import React from 'react';
import PropTypes from 'prop-types';
import {Formik} from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import _ from "lodash";

import ProjectDetailFormComponent from './ProjectDetailFormComponent';
import {PROJECT_STATUS_LIST, renameObjectArrayProps} from '../../helpers';
import {DATE, REQUIRED} from "../FormElements/messages";

// valori iniziali del form
const initialValues = {
    CdgProjectStartDate: moment(),
    CdgProjectEndDate: moment().add(6, 'month'),
    CdgProjectName: '',
    CdgProjectCodeId: 1,
    CdgProjectClientName: '',
    CdgProjectPracticeId: null,
    CdgProjectCategoryId: null,
    CdgProjectSellerId: null,
    CdgProjectStatus: 'A',
    CdgProjectPlannedInternalRevenues: 0,
    CdgProjectPlannedExternalRevenues: 0,
    CdgProjectPlannedTotalRevenues: 0,
    CdgProjectPlannedTotalRevenuesPerc: 0,
    CdgProjectSellingPrice: 0,
    CdgProjectPlannedWIPRevenues: 0,
    CdgProjectEffectiveInternalRevenues: 0,
    CdgProjectEffectiveExternalRevenues: 0,
    CdgProjectEffectiveTotalRevenues: 0,
    CdgProjectEffectiveTotalRevenuesPerc: 0,
    CdgProjectEffectiveWIPRevenues: 0,
    CdgProjectPlannedIntellectualRevenues: 0,
    CdgProjectBacklog: 0,
};

// cross validation
const validate = values => {
    let errors = {};
    // if (!values.requested_audio && !values.requested_video) {
    //   errors.requested_audio = 'Seleziona almeno uno tra Audio e Video';
    // }
    return errors;
};

const string2number = formatted => formatted === null ? 0 : isNaN(parseFloat(formatted)) ? formatted.replace('€ ', '').replace(/\./g, '').replace(',', '.') : parseFloat(formatted);

const formSchema = Yup.object().shape({
    CdgProjectStartDate: Yup.date()
        .required(REQUIRED),
    CdgProjectEndDate: Yup.date()
        .min((Yup.ref('CdgProjectStartDate')), DATE)
        .required(REQUIRED),
    CdgProjectStatus: Yup.string().nullable().required(REQUIRED),
    CdgProjectName: Yup.string().required(REQUIRED),
    CdgProjectCodeId: Yup.string().nullable(),
    CdgProjectClientName: Yup.string().nullable(),
    CdgProjectPracticeId: Yup.string().required(REQUIRED),
    CdgProjectCategoryId: Yup.string().nullable(),
    CdgProjectSellerId: Yup.string().nullable(),
    // CdgProjectPlannedExternalRevenues: Yup.number().min(0).nullable(),
    // CdgProjectSellingPrice: Yup.number().min(0).nullable(),
});


const ProjectDetailFormContainer = ({openDateModal, openSubmitModal, project, isNewProject, managers, categories, practices, codes, doSubmit, doRefresh}) => {
    return(<Formik
        enableReinitialize={true}
        initialValues={{...initialValues, ...project}}
        validationSchema={formSchema}
        validate={validate}
        onSubmit={(values, {setSubmitting}) => {
            const newValues = {
                Id: values.Id,
                CdgProjectStartDate: values.CdgProjectStartDate,
                CdgProjectEndDate: values.CdgProjectEndDate,
                CdgProjectStatus: values.CdgProjectStatus,
                CdgProjectName: values.CdgProjectName,
                CdgProjectCodeId: values.CdgProjectCodeId,
                CdgProjectClientName: values.CdgProjectClientName,
                CdgProjectPracticeId: values.CdgProjectPracticeId,
                CdgProjectCategoryId: values.CdgProjectCategoryId,
                CdgProjectSellerId: values.CdgProjectSellerId,
                CdgProjectPlannedExternalRevenues: string2number(values.CdgProjectPlannedExternalRevenues),
                CdgProjectSellingPrice: string2number(values.CdgProjectSellingPrice),
                CdgProjectPlannedIntellectualRevenues: string2number(values.CdgProjectPlannedIntellectualRevenues)
            };
            doSubmit(newValues, {setSubmitting});
        }}
        render={props => {
            const categoriesList = renameObjectArrayProps(categories, ['Id', 'CdgProjectCategoryName']);
            const practicesList = renameObjectArrayProps(practices, ['Id', 'CdgPracticeCodeName']);
            const codesList = renameObjectArrayProps(codes, ['Id', 'CdgProjectCodeExternalReferenceId']);
            const managersList = renameObjectArrayProps(managers.map(m => ({
                ...m,
                FullName: `${m.FirstName} ${m.LastName}`
            })), ['Id', 'FullName']);
            return (
                <ProjectDetailFormComponent {...props}
                                            isNewProject={isNewProject}
                                            openDateModal={openDateModal}
                                            openSubmitModal={openSubmitModal}
                                            categoriesList={categoriesList}
                                            practicesList={practicesList}
                                            codesList={codesList}
                                            managersList={managersList}
                                            statusList={PROJECT_STATUS_LIST}
                                            doRefresh={doRefresh}
                />);
        }
        }
    />)
    
    ;}

ProjectDetailFormContainer.propTypes = {
    doSubmit: PropTypes.func.isRequired
};

ProjectDetailFormContainer.defaultProps = {
};

const areEqual = (prevProps, nextProps) =>{
    return prevProps.project.CdgProjectPlannedInternalRevenues === nextProps.project.CdgProjectPlannedInternalRevenues &&
        prevProps.project.CdgProjectPlannedExternalRevenues === nextProps.project.CdgProjectPlannedExternalRevenues &&
        prevProps.project.CdgProjectPlannedIntellectualRevenues === nextProps.project.CdgProjectPlannedIntellectualRevenues
} 


export default React.memo(ProjectDetailFormContainer,areEqual);
