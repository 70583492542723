import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getUnassignedCodes, getDummyProject } from "../../reducers";
import { Container } from 'reactstrap';
import Loader from '../Loader';
import ProjectReconciliationDetailComponent from './ProjectReconciliationDetailComponent';


const ReconciliationDetailContainer = ({ getUnassignedCodes, getDummyProject, codes, projects, match: { params: { id } } }) => {
  const [project, setProject] = useState();
  useEffect(() => {
    getUnassignedCodes();
    getDummyProject(id)
  }, [getUnassignedCodes, getDummyProject, id]);

  useEffect(() => {
    const project = projects.data?.find(p => p.id === parseInt(id));
    setProject(project);
  }, [projects, id]);

  // return(JSON.stringify([project, codes.data]));
  return (
    <Container fluid>
      {codes.fetched &&  projects.fetched &&
      <ProjectReconciliationDetailComponent 
        project={project} 
        data={codes.data}
      />}
      <Loader isLoading={codes.fetching || projects.fetching} />
    </Container>
  );
};

const mapStateToProps = ({
  codes,
  dummyProjects
}) => {
  return {
    codes,
    projects: dummyProjects
  };
};

const mapDispatchToProps = {
  getUnassignedCodes,
  getDummyProject
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ReconciliationDetailContainer));
