import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Container } from 'reactstrap';
import ImportFileComponent from './ImportFileComponent';
import Loader from '../Loader';

import { errors } from '../FormElements';
import api from '../../api';
const { REQUIRED } = errors;

const importList = ['Resources', 'Projects'];

const initialValues = {
  importType: '',
  file: '',
};

const formSchema = Yup.object().shape({
  importType: Yup.string().required(REQUIRED)
  .test('valid-selection', REQUIRED, function(value) { 
    return importList.includes(value);
  }),
  file: Yup.object().required(REQUIRED),
});

const ImportFileContainer = () => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();

  const doImportFile = async ({ importType, file }, { resetForm }) => {
    try {
      setError();
      setLoading(true);
      const { data } = await api.getWriteData(file.name);
      await api.uploadFileAws(data.url, file.file);
      const result = await api.importFile(importType, data.filename);
      setLoading(false);
      setSuccess({ message: 'Import Job Started' });
      resetForm();
      // setBatchId(result.data.batchId);
      setTimeout(() => setSuccess(), 3000);
    } catch (er) {
      setLoading(false);
      setError(
        { ...er,
          messaggio: 'Error uploading file. '
        });
      setSuccess();
    }
  };
  
  return (
    <Container>
      {isLoading && <Loader isLoading={isLoading} />}
      {!isLoading && <Formik
        initialValues={initialValues}
        validationSchema={formSchema}
        onSubmit={doImportFile}
        render={props => <ImportFileComponent {...props} errore={error} success={success} importList={importList} />}
      />}
    </Container>
  );
};

ImportFileContainer.propTypes = {};

ImportFileContainer.defaultProps = {};

export default ImportFileContainer;