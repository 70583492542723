import React, {useContext} from 'react';

import NavbarComponent from './NavbarComponent';
import { AuthContext } from '../../authentication';

const NavbarContainer = () => {
  const {isAuth, isPM, isAreaManager, isFinance, isImportAdmin, logout} = useContext(AuthContext);
  return (
    <NavbarComponent isAuth={isAuth} isPM={isPM} isAreaManager={isAreaManager} isFinance={isFinance} isImportAdmin={isImportAdmin} doLogout={logout}/>
  );
};

NavbarContainer.propTypes = {};

NavbarContainer.defaultProps = {};

export default NavbarContainer;