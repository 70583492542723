import React from 'react';
// import PropTypes from 'prop-types';
import {Row, Col} from 'reactstrap';
import ReactTable from 'react-table';
import withFixedColumns from "react-table-hoc-fixed-columns";

import "react-table/react-table.css";
import "react-table-hoc-fixed-columns/lib/styles.css";

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const ProjectWorkforceComponent = ({columnDefinition, data, size, setSize}) => {
    return (
        <Row>
            <Col md={12}>
                <ReactTableFixedColumns
                    columns={columnDefinition}
                    data={data}
                    defaultPageSize={size}
                    className="-striped -highlight"
                    style={{
                      width:'100%',
                      overflow:'auto'
                    }}
                    renderPageSizeOptions= {({
                        pageSize,
                        pageSizeOptions,
                        rowsSelectorText,
                        onPageSizeChange,
                        rowsText,
                      }) =>{
                          return (
                            <span className="select-wrap -pageSizeOptions">
                              <select
                                aria-label={rowsSelectorText}
                                onChange={e => {onPageSizeChange(Number(e.target.value))
                                setSize(Number(e.target.value))
                                }}
                                value={pageSize}
                              >
                                {pageSizeOptions.map((option, i) => (
                                  <option key={i} value={option}>
                                    {`${option} ${rowsText}`}
                                  </option>
                                ))}
                              </select>
                            </span>
                          );
                      } }
                />
            </Col>
        </Row>
    );
}

ProjectWorkforceComponent.propTypes = {};

ProjectWorkforceComponent.defaultProps = {};

export default React.memo(ProjectWorkforceComponent);