import React, { useContext } from 'react';

import { AuthContext } from '../../authentication';

const withUser = WrappedComponent => {
  const FwithUser = props => {
    const { userid, isAreaManager, isFinance, isImportAdmin } = useContext(AuthContext);
    return <WrappedComponent {...props} userid={userid} isAreaManager={isAreaManager} isFinance={isFinance} isImportAdmin={isImportAdmin} />;
  };
  return FwithUser;
};

export default withUser;