import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Container, Button, Form, Alert} from 'reactstrap';

import { RowInput } from '../FormElements';

import styles from  './LoginForm.module.css';

const LoginFormComponent = ({
  values,
  touched,
  errors,
  status,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  isSubmitting,
  isValid,
  errore
}) => 
  {return(  
      <Container className={styles.login}>
        <Form onSubmit={handleSubmit} >
          <h3>Authentication</h3>
          <div className={styles.content}>
            <Field type="text" label="Email" name="email" component={RowInput} autoFocus />
            <Field type="password" label="Password" name="password" component={RowInput} />
          </div>
          {errore && (<Alert color="danger"> {errore.messaggio + (errore.response.data.Error || '') } </Alert>)}
          <div className={styles.submit}>
            <Button color="success" type="submit" disabled={isSubmitting || !isValid}>Login</Button>
          </div>
        </Form>
      </Container>
  )}


LoginFormComponent.propTypes = {
  errore: PropTypes.object,
};

LoginFormComponent.defaultProps = {
  errore: null
};

export default LoginFormComponent;