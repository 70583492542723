import React, { useEffect, useState } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import AreaRevenuesComponent from "./AreaRevenuesComponent";
import { setTotalColumns, setAreaColumns } from "./utils";
import { Container } from "reactstrap";
import Loader from "../Loader";
import { getAreaRevenues } from "../../reducers";
import { AREA_DETAIL_REVENUES } from '../../navigation/routes';

const columns = setTotalColumns;
const areaColumns = setAreaColumns;

function AreaRevenuesContainer({ areaRevenues, getAreaRevenues, match: { params: { id }} }) {
  const [areaId, setAreaId] = useState(id);
  useEffect(() => {
    setAreaId(id);
    getAreaRevenues(id);
  }, [getAreaRevenues, id]);

  const getAreaRevenuesData = () => {
    const tableData = [...areaRevenues.data];
    return tableData;
  }

  if(!areaRevenues) return null;

  // singola area mi sposto dai totali a quella singola area  (2 righe perchè ci sono i totali)
  if (!areaId && areaRevenues?.data?.length === 2) {
    const id = areaRevenues.data[1].Practice;
    return <Redirect to={`${AREA_DETAIL_REVENUES}/${id}`} />
  }
  return (
    <Container fluid>
      {areaRevenues.fetched && (
        <AreaRevenuesComponent data={getAreaRevenuesData()} columns={areaId ? areaColumns : columns} isDetail={!!areaId} />
      )}
      {areaRevenues.errors && areaRevenues.errors.length > 0 && (
        <p>{areaRevenues.errors}</p>
      )}
      <Loader isLoading={areaRevenues.fetching} />
    </Container>
  );
}

AreaRevenuesContainer.defaultProps = {
  areaRevenues: [],
};

const mapStateToProps = ({ areaRevenues }) => {
  return {
    areaRevenues,
  };
};

const mapDispatchToProps = {
  getAreaRevenues,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AreaRevenuesContainer));
