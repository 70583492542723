// https://codesandbox.io/s/github/tannerlinsley/react-table/tree/v6/examples/editable-content?file=/src/index.js
import React from "react";

import styles from  '../Projects/Projects.module.css';
import { Row, Col, Alert, Button } from "reactstrap";
import ReactTable from "react-table";
import api from "../../api";
import { useHistory } from 'react-router-dom';

import "react-table/react-table.css";
import "react-table-hoc-fixed-columns/lib/styles.css";

const addButtonHeader = (addRow) => {
  const _click = () => {
      addRow();
  }
  return (
      <button type="button" onClick={_click} className="btn btn-primary btn-sm">
          <i className="fa fa-plus"></i>
      </button>
  );
};

const confirmButtonCell = (props, confirmRow) => {
  const _click = () => {
    confirmRow(props.original.CdgProjectCodeExternalReferenceId);
  }
  return (
      <button type="button" onClick={_click} className="btn btn-outline-info btn-sm">
          <i className="fa fa-check"></i>
      </button>
  );
};

const headerStyle = {fontWeight: 'bold', marginTop: 5};
const columnDefs = (addRow, confirmRow, renderEditable) => [
  {
    Header: addButtonHeader(addRow),
    accessor: 'Id',
    minWidth: 50,
    Cell: props => confirmButtonCell(props, confirmRow),
    filterable: false,
    sortable: false,
  },
  {Header: 'Code', accessor: 'CdgProjectCodeExternalReferenceId', minWidth: 100, Cell: renderEditable, headerStyle},
  {Header: 'Client', accessor: 'CdgClientName', minWidth: 100, Cell: renderEditable, headerStyle},
  {Header: 'Project', accessor: 'CdgProjectCodeName', minWidth: 100, Cell: renderEditable, headerStyle},
];


function ProjectReconciliationDetailComponent(props) {
  const history = useHistory();

  const [data, setData] = React.useState(props.data)
  const [alert, setAlert] = React.useState({
    messge: "",
    visible: false,
    color: "warning",
  });

  const onAlertDismiss = React.useCallback(() => {
    setAlert({
      messge: "",
      visible: false,
    });
  },[]);

  const onAlertOpen = React.useCallback((message, color = "warning") => {
    setAlert({
      messge: message,
      visible: true,
      color,
    });
  },[]);

  React.useEffect(() => {
    setData(props.data)
  },[props.data])

  if (data?.lenght === 0) {
    return null
  }

  const save = async (code) => {
    try {
      await api.reconcileProject(props.project.id, code);
      onAlertOpen("Project reconciled", "success");
      setTimeout(history.goBack, 3000);
    } catch (error) {
      console.error(error);
      onAlertOpen("Can't reconcile project");
    }
  }


  const addRow = () => {
    const emptyRow = data.find(x => x.Id === null);
    if(emptyRow) return;
    const newRow = { Id: null, CdgProjectId: props.project.Id, CdgConsultantName: '', CdgConsultantDailyRate: 0 };
    const newData = [newRow, ...data];
    setData(newData);
  }

  async function confirmRow(id) {
    if (id) save(id);
  }

  const renderEditable = (cellInfo) => {
    return (
      <div
        style={{ backgroundColor: "#fafafa", marginTop: '10px' }}
        contentEditable={cellInfo.column.id === 'CdgProjectCodeExternalReferenceId' && !cellInfo.original.Id}
        suppressContentEditableWarning
        onBlur={e => {
          const newData = [...data];
          newData[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
          setData(newData);
        }}
        dangerouslySetInnerHTML={{
          __html: data[cellInfo.index][cellInfo.column.id]
        }}
      />
    );
  }

  return (
    <>
      <Alert
          className="sticky-top pb-2"
          color={alert.color}
          isOpen={alert.visible}
          toggle={onAlertDismiss}
        >
          <h6>{alert.messge}</h6>
      </Alert>
      <Row className={styles.title}>
        <Col md={10}>
          <h3 className={styles.header}>
            <i className="fa fa-tasks"></i>
            <span className={styles.text}>{`${props.project?.CdgProjectCodeExternalReferenceId} ${props.project?.CdgProjectName} - Code Reconciliation Selection`}</span>
          </h3>
        </Col>
        <Col>
            <Button color="secondary" onClick={history.goBack} >&nbsp;Back&nbsp;</Button>
        </Col>
      </Row>
      <Row>
        <Col md={{offset: 2, size: 8}}>
          <ReactTable
            columns={columnDefs(addRow, confirmRow, renderEditable)}
            data={data}
            defaultPageSize={10}
            className="-striped -highlight"
            style={{ width: "100%", overflow: "auto" }}
            filterable={true}
          />
        </Col>
      </Row>
    </>
  );
}

export default React.memo(ProjectReconciliationDetailComponent);
