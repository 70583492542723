import React from 'react';
import UnauthorizedComponent from './UnauthorizedComponent';

const UnauthorizedContainer = () =>

  <UnauthorizedComponent />


  UnauthorizedContainer.propTypes = {};

  UnauthorizedContainer.defaultProps = {};

export default UnauthorizedContainer;