import { Link } from "react-router-dom";
import { AREA_DETAIL_REVENUES, AREA_RESOURCE_DETAIL } from '../../navigation/routes';
import { startFilterMethod, includesFilterMethod } from '../../helpers/filters';

const headerStyle = {fontWeight: 'bold'};

const makeLink = row => `${AREA_DETAIL_REVENUES}/${row.original.Practice}`;
const makeResourceLink = row => row.original.TotalDays > 0 ? <Link to={`${AREA_RESOURCE_DETAIL}/${row.original.Id}`}>{leftAlign(row)}</Link> : leftAlign(row);
const leftAlign = row => <div style={{textAlign: 'left'}}>{row.value}</div>

const mapper = cols => cols.map(col => ({...col, headerStyle }));



const COMMON_COLUMNS = [
  { Header: "Curr Month Planned Days", accessor: "CurMonthPlannedDay", fixed: "left", minWidth: 180, maxWidth: 300 },
  { Header: "Curr Month Revenues", accessor: "CurMonthRevenues", fixed: "left", minWidth: 150, maxWidth: 300 },
  { Header: "Curr Month Dummy", accessor: "CurMonthDummyRevenues", fixed: "left", minWidth: 150, maxWidth: 300 },
  { Header: "Jan Planned Days", accessor: "JanPlanDays", minWidth: 120, maxWidth: 300 },
  { Header: "Jan Planned Revenues", accessor: "JanPlanRev", minWidth: 120, maxWidth: 300 },
  { Header: "Feb Planned Days", accessor: "FebrPlanDays", minWidth: 120, maxWidth: 300 },
  { Header: "Feb Planned Revenues", accessor: "FebrPlanRev", minWidth: 120, maxWidth: 300 },
  { Header: "Mar Planned Days", accessor: "MarchPlanDays", minWidth: 120, maxWidth: 300 },
  { Header: "Mar Planned Revenues", accessor: "MarchPlanRev", minWidth: 120, maxWidth: 300 },
  { Header: "Apr Planned Days", accessor: "AprPlanDays", minWidth: 120, maxWidth: 300 },
  { Header: "Apr Planned Revenues", accessor: "AprPlanRev", minWidth: 120, maxWidth: 300 },
  { Header: "May Planned Days", accessor: "MayPlanDays", minWidth: 120, maxWidth: 300 },
  { Header: "May Planned Revenues", accessor: "MayPlanRev", minWidth: 120, maxWidth: 300 },
  { Header: "Jun Planned Days", accessor: "JunPlanDays", minWidth: 120, maxWidth: 300 },
  { Header: "Jun Planned Revenues", accessor: "JunPlanRev", minWidth: 120, maxWidth: 300 },
  { Header: "Jul Planned Days", accessor: "JulPlanDays", minWidth: 120, maxWidth: 300 },
  { Header: "Jul Planned Revenues", accessor: "JulPlanRev", minWidth: 120, maxWidth: 300 },
  { Header: "Aug Planned Days", accessor: "AugPlanDays", minWidth: 120, maxWidth: 300 },
  { Header: "Aug Planned Revenues", accessor: "AugPlanRev", minWidth: 120, maxWidth: 300 },
  { Header: "Sep Planned Days", accessor: "SepPlanDays", minWidth: 120, maxWidth: 300 },
  { Header: "Sep Planned Revenues", accessor: "SepPlanRev", minWidth: 120, maxWidth: 300 },
  { Header: "Oct Planned Days", accessor: "OctPlanDays", minWidth: 120, maxWidth: 300 },
  { Header: "Oct Planned Revenues", accessor: "OctPlanRev", minWidth: 120, maxWidth: 300 },
  { Header: "Nov Planned Days", accessor: "NovPlanDays", minWidth: 120, maxWidth: 300 },
  { Header: "Nov Planned Revenues", accessor: "NovPlanRev", minWidth: 120, maxWidth: 300 },
  { Header: "Dec Planned Days", accessor: "DecPlanDays", minWidth: 120, maxWidth: 300 },
  { Header: "Dec Planned Revenues", accessor: "DecPlanRev", minWidth: 120, maxWidth: 300 },
  { Header: "Target Days", accessor: "TargetDays", minWidth: 120, maxWidth: 300 },
  { Header: "Annual Charged Days", accessor: "AnnualChargedDays", minWidth: 120, maxWidth: 300 },
  { Header: "Annual Revenues", accessor: "AnnualRevenues", minWidth: 120, maxWidth: 300 },
  { Header: "Annual Planned Days", accessor: "AnnualPlannedDays", minWidth: 120, maxWidth: 300 },
  { Header: "Annual Planned Revenues", accessor: "AnnualPlannedRevenues", minWidth: 120, maxWidth: 300 },
  { Header: "Total Days", accessor: "TotalDays", minWidth: 120, maxWidth: 300 },
  { Header: "Total Revenues", accessor: "TotalRevenues", minWidth: 120, maxWidth: 300 },
  { Header: "Total Utilization", accessor: "TotalUtilization", minWidth: 120, maxWidth: 300 },
  { Header: "Current Utilization", accessor: "CurrentUtilization", minWidth: 120, maxWidth: 300 },
];

const LEFT_TOTAL_COLUMNS = [
  { Header: "Area", accessor: "Practice", fixed: "left", minWidth: 80, Cell: row => row.value ? <Link to={makeLink(row)}>{row.value}</Link> : <div>Totale</div> },
];

const LEFT_AREA_COLUMNS = [
  { Header: "Name", accessor: "Name", fixed: "left", minWidth: 120, 
    Cell: row => row.value !== ' Totale' ? makeResourceLink(row) : <div>Totale</div>, 
    filterable: true, filterMethod: includesFilterMethod },
  { Header: "Level", accessor: "Level", fixed: "left", minWidth: 50, maxWidth: 300, filterable: true, filterMethod: startFilterMethod },
  { Header: "Daily Fee", accessor: "DailyFee", fixed: "left", minWidth: 120, maxWidth: 300, filterable: true },
];

const TOTAL_COLUMNS = [...LEFT_TOTAL_COLUMNS, ...COMMON_COLUMNS];

const AREA_COLUMNS = [...LEFT_AREA_COLUMNS, ...COMMON_COLUMNS];


export const setTotalColumns = mapper(TOTAL_COLUMNS);
export const setAreaColumns = mapper(AREA_COLUMNS);