import React, { useState } from "react";
// import {connect} from 'react-redux';
// import {getProjects} from '../../reducers';
import ProjectWorkforceComponent from "./ProjectWorkforceComponent";
import WorkForceDefinition from "../TableElements";
import api from "../../api";
import _ from "lodash";
import { toNumber } from "../../helpers";

function ProjectWorkforceContainer(props) {
  const [projectWorkforce, setProjectWorkforce] = useState(props.projectWorkforce);
  const [row, setRow] = useState();
  const [keyId, setKeyId] = useState();
  async function addWorkForceItem() {
    // elimina dalla lista i non attivi e gli id già nel progetto
    const workForceId = props.workforce
      ? (
          props.workforce.filter(
            (elm) =>
              elm.CdgWorkforceStatus === "A" &&
              !projectWorkforce.find((p) => p.CdgWorkforceId === elm.Id)
          )[0] || {}
        ).Id
      : 0;
    const projectId = props.project ? props.project : 0;
    if (!workForceId) {
      props.openWarning("Can't add workforce item");
      return;
    }
    const newForceItem = {
      CdgProjectId: projectId,
      CdgWorkforceId: workForceId,
      CdgProjectWorkforcePlannedDays: 0,
      CdgProjectWorkforceEffectiveDays: 0,
      CdgProjectWorkforceEffectiveRevenues: 0,
    };
    try {
      await api.addWorkForce(newForceItem);
      props.getUpdatedWorForce();
    } catch (error) {
      props.openWarning("Can't add workforce item");
    }
  }

  async function updateWorkForceItem(rowId, key, value) {
    let updatedForceItem = {};
    const workForceItem = _.find(projectWorkforce, ["Id", rowId]);
    if (workForceItem) {
      // PM 20190727 aggiorna anche il totale pianificato
      const totKey = "CdgProjectWorkforcePlannedDays";
      const oldValue = toNumber(_.get(workForceItem, key, 0));
      const totValue = toNumber(_.get(workForceItem, totKey, 0));
      const newValue = toNumber(value);

      updatedForceItem = _.set(workForceItem, key, newValue);
      updatedForceItem = _.set(
        workForceItem,
        totKey,
        totValue - oldValue + newValue
      );
    }
    
    try {
      let newData = [...projectWorkforce];
      setProjectWorkforce(newData)
      api.updateWorkForce(updatedForceItem);
    } catch (error) {
      props.openWarning("Can't update workforce item");
    }
  }

  async function removeWorForceItem(id) {
    try {
      await api.removeWorkForce(id);
      props.getUpdatedWorForce();
    } catch (error) {
      props.openWarning("Can't remove workforce item");
    }
  }

  const columnDefinition = WorkForceDefinition(
    addWorkForceItem,
    updateWorkForceItem,
    removeWorForceItem,
    props.workforce,
    // availableWorkforce: elimina dalla lista i non attivi e gli id già nel progetto
    props.workforce.filter(
      (elm) =>
        elm.CdgWorkforceStatus === "A" &&
        !projectWorkforce.find((p) => p.CdgWorkforceId === elm.Id)
    ),
    projectWorkforce,
    row,
    setRow,
    keyId,
    setKeyId
  );
  
  return (
    <ProjectWorkforceComponent
      columnDefinition={columnDefinition}
      data={projectWorkforce}
      size={props.size}
      setSize={props.setSize}
    />
  );
}

ProjectWorkforceContainer.propTypes = {};

ProjectWorkforceContainer.defaultProps = {
  memberList: [],
};

export default React.memo(ProjectWorkforceContainer);
