import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Container, Button, Form, Alert} from 'reactstrap';

import { RowInput } from '../FormElements';

import styles from  './ChangePassword.module.css';

const ChangePasswordComponent = ({
  values,
  touched,
  errors,
  status,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  isSubmitting,
  isValid,
  errore,
  success
}) => 
  {return(  
      <Container className={styles.login}>
        <Form onSubmit={handleSubmit} >
          <h3>Change Password</h3>
          <div className={styles.content}>
            <Field type="password" label="Current Password" name="oldPassword" labelcols={5} component={RowInput} autoFocus />
            <Field type="password" label="New Password" name="newPassword" labelcols={5} component={RowInput} />
            <Field type="password" label="Repeat New Password" name="repeatPassword" labelcols={5} component={RowInput} />
          </div>
          {errore && (<Alert color="danger"> {errore.messaggio + (errore.response.data.Error || '') } </Alert>)}
          {success && (<Alert color="success"> {success.message} </Alert>)}
          <div className={styles.submit}>
            <Button color="success" type="submit" disabled={isSubmitting || !isValid}>Save</Button>
          </div>
        </Form>
      </Container>
  )}


  ChangePasswordComponent.propTypes = {
  errore: PropTypes.object,
  success: PropTypes.object
};

ChangePasswordComponent.defaultProps = {
  errore: null,
  success: null
};

export default ChangePasswordComponent;