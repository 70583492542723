import api from '../api';

const API = 'categories';
const PENDING = `${API}_PENDING`;
const FULFILLED = `${API}_FULFILLED`;
const REJECTED = `${API}_REJECTED`;
const FOUND = `${API}_FOUND`;

const initialState = {
  data: [],
  currentId: null,
  fetching: false,
  fetched: false,
  errors: []
};

export default (state = initialState, action = {}) => {
  const { type, payload, meta } = action;
  switch (type) {
    case PENDING: return {...state, fetching: true, fetched: false};
    case REJECTED: return {...initialState, errors: payload};
    case FULFILLED: return { data: [...payload.data], currentId: meta, fetching: false, fetched: true};
    case FOUND: return state; // just for clarity
    default: return state;
  }
}

// API
export const apiCategories = () => ({
  type: API,
  payload: api.getCategories()
});

// Action Creator
export const getCategories = () => {
  return (dispatch, getState) => {
    const { categories } = getState();
    if (categories.fetched) dispatch({ type: FOUND});
    else dispatch(apiCategories());
  };
}