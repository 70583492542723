import React from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading'; 

import { Container, Row, Col } from 'reactstrap';

import styles from  './Loader.module.css';

const Loader = ({
  isLoading,
  type,
  color,
  height,
  width
}) =>
 <Container fluid className={styles.loader}>
    <Row>
      <Col xs="12">
        {isLoading ?
          <ReactLoading className="mx-auto d-block" type={type} color={color} height={height} width={width} /> :
          null
        }
      </Col>
    </Row>
 </Container>

Loader.propTypes = {
   isLoading: PropTypes.bool.isRequired,
   type: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.number,
   width: PropTypes.number
};

Loader.defaultProps = {
  type: 'bars',
  color: '#007BFF',
  height: 150,
  width: 150
};

 export default Loader;
