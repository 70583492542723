import NumberFormat from 'react-number-format';
import { includesFilterMethod } from '../../helpers/filters';
import { numberSortMethod } from '../../helpers/sort';

const headerStyle = { fontWeight: 'bold' };

const leftAlign = row => <div style={{textAlign: 'left'}}>{row.value}</div>

const numberCell = row => <NumberFormat thousandSeparator={'.'} prefix={'€ '} decimalSeparator={','} value={row.value} displayType={'text'} disabled />

const mapper = cols => cols.map(col => ({...col, headerStyle }));


const COMMON_COLUMNS = [
  { Header: "Jan C", accessor: "JanC", minWidth: 50 },
  { Header: "Jan P", accessor: "JanP", minWidth: 50 },
  { Header: "Feb C", accessor: "FebrC", minWidth: 50 },
  { Header: "Feb P", accessor: "FebrP", minWidth: 50 },
  { Header: "Mar C", accessor: "MarchC", minWidth: 50 },
  { Header: "Mar P", accessor: "MarchP", minWidth: 50 },
  { Header: "Apr C", accessor: "AprC", minWidth: 50 },
  { Header: "Apr P", accessor: "AprP", minWidth: 50 },
  { Header: "May C", accessor: "MayC", minWidth: 50 },
  { Header: "May P", accessor: "MayP", minWidth: 50 },
  { Header: "Jun C", accessor: "JunC", minWidth: 50 },
  { Header: "Jun P", accessor: "JunP", minWidth: 50 },
  { Header: "Jul C", accessor: "JulC", minWidth: 50 },
  { Header: "Jul P", accessor: "JulP", minWidth: 50 },
  { Header: "Aug C", accessor: "AugC", minWidth: 50 },
  { Header: "Aug P", accessor: "AugP", minWidth: 50 },
  { Header: "Sep C", accessor: "SeptC", minWidth: 50 },
  { Header: "Sep P", accessor: "SeptP", minWidth: 50 },
  { Header: "Oct C", accessor: "OctC", minWidth: 50 },
  { Header: "Oct P", accessor: "OctP", minWidth: 50 },
  { Header: "Nov C", accessor: "NovC", minWidth: 50 },
  { Header: "Nov P", accessor: "NovP", minWidth: 50 },
  { Header: "Dec C", accessor: "DecC", minWidth: 50 },
  { Header: "Dec P", accessor: "DecP", minWidth: 50 },
];

const LEFT_TOTAL_COLUMNS = [
  { Header: "Name", accessor: "Name", fixed: "left", minWidth: 120, Cell: row => leftAlign(row), filterable: true, filterMethod: includesFilterMethod },
  { Header: "Level", accessor: "Level", fixed: "left", minWidth: 50, filterable: true, filterMethod: includesFilterMethod },
  { Header: "Tot Charged YTD [€]", accessor: "TotalMDCharged", fixed: "left", minWidth: 140, filterable: true, filterMethod: includesFilterMethod, Cell: numberCell, sortMethod: numberSortMethod },
  { Header: "Tot Charged Mon", accessor: "TotalNumMDCharged", fixed: "left", minWidth: 120, filterable: true, filterMethod: includesFilterMethod },
  { Header: "Month Utilization", accessor: "MonthUtilization", fixed: "left", minWidth: 120, filterable: true, filterMethod: includesFilterMethod },
  { Header: "Month Capacity", accessor: "MonthCapacity", fixed: "left", minWidth: 120, filterable: true, filterMethod: includesFilterMethod },
  { Header: "Annual Utilization", accessor: "AnnualUtilization", fixed: "left", minWidth: 120, filterable: true, filterMethod: includesFilterMethod },
  { Header: "Annual Capacity", accessor: "AnnualCapacityUsage", fixed: "left", minWidth: 120, filterable: true, filterMethod: includesFilterMethod },
];

const TOTAL_COLUMNS = [...LEFT_TOTAL_COLUMNS, ...COMMON_COLUMNS];
export const setTotalColumns = () => mapper(TOTAL_COLUMNS);
