import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Field} from 'formik';
import {Row, Col, Button, Form, Collapse} from 'reactstrap';
import {RowInput, RowSelectAutocompleteInput, RowDatePickerInput, RowNumberFormatInput} from '../FormElements';
import { Link } from 'react-router-dom';
import { CONSULTANTS } from '../../navigation/routes';

import styles from  './ProjectDetailForm.module.css';

const formatDate = dt => {
    const date = moment(dt);
    return date.isValid() ? date.format("DD-MM-YYYY") :  '';
};


const ProjectDetailForm = ({
                               openDateModal,
                               openSubmitModal,
                               isNewProject,
                               codesList,
                               categoriesList,
                               practicesList,
                               managersList,
                               statusList,
                               values,
                               handleSubmit,
                               setFieldValue,
                               isSubmitting,
                               doRefresh
                           }) => {

    const [isOpen, toggle] = useState(!values.Id);
    const isProjectCodeId = 1;

    const updateProjectCodeRealated = (value) => {
        if (isProjectCodeId !== value['Id']) {
            setFieldValue("CdgProjectName", value['CdgProjectCodeName']);
            setFieldValue("CdgProjectClientName", value['CdgClientName']);
        }
        setFieldValue("CdgProjectCodeId", value['Id']);

    }

    return (
        <Form>
            <Row>
                <Col xs={12}>
                    <Row>
                        <Col xs={12} className="text-left">
                            <h5 
                                onClick={() => toggle(!isOpen)}
                                className={styles.toggle}
                            >
                                {isOpen ? <i className="fa fa-caret-down"></i> : <i className="fa fa-caret-right"></i>}
                                <span className={styles.detail} >
                                    {`Project Detail: ${values.CdgProjectName || 'New Project'} (${formatDate(values.CdgProjectStartDate)} - ${formatDate(values.CdgProjectEndDate)})`}
                                </span>
                            </h5>
                        </Col>
                    </Row>
                    <Collapse isOpen={isOpen}>
                        <Row>
                            <Col md={4}>
                                <Field className="form-control-sm" formGroupClass=" mb-1" type="date" label="Start Date"
                                    name="CdgProjectStartDate" labelcols={4} component={RowDatePickerInput}
                                    onChange={value => {
                                        setFieldValue("CdgProjectStartDate", value);
                                        if (values["Id"]){openDateModal()};
                                    }}
                                    disabled showuntouched/>
                            </Col>
                            <Col md={4}>
                                <Field className="form-control-sm" formGroupClass=" mb-1" type="date" label="End Date"
                                    name="CdgProjectEndDate" labelcols={4} component={RowDatePickerInput}
                                    onChange={value => {
                                        setFieldValue("CdgProjectEndDate", value);
                                        if (values["Id"]){openDateModal()};
                                    }}
                                    disabled showuntouched/>
                            </Col>
                            <Col md={4}>
                                <Field className="form-control-sm" formGroupClass=" mb-1" label="Project Status"
                                    name="CdgProjectStatus"
                                    onChange={value => setFieldValue("CdgProjectStatus", value['id'])}
                                    list={statusList} labelcols={4} defaultValue={statusList[0]}
                                    disabled
                                    component={RowSelectAutocompleteInput}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <Field className="form-control-sm" formGroupClass=" mb-1" type="text" label="Project Name"
                                    name="CdgProjectName" disabled={!isNewProject || isProjectCodeId !== values["CdgProjectCodeId"]}
                                    labelcols={4} component={RowInput} />
                            </Col>
                            <Col md={4}>
                                <Field className="form-control-sm" formGroupClass=" mb-1" label="Project Code"
                                    name="CdgProjectCodeId" onChange={updateProjectCodeRealated}
                                    defaultValue={codesList[0]}
                                    list={codesList} labelcols={4} component={RowSelectAutocompleteInput}
                                    disabled />
                            </Col>
                            <Col md={4}>
                                <Field className="form-control-sm" formGroupClass=" mb-1" type="text" label="Client Name"
                                    name="CdgProjectClientName" disabled={!isNewProject || isProjectCodeId !== values["CdgProjectCodeId"]}
                                    labelcols={4} component={RowInput} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <Field className="form-control-sm" formGroupClass=" mb-1" label="Practice"
                                    name="CdgProjectPracticeId"
                                    onChange={value => setFieldValue("CdgProjectPracticeId", value['id'])}
                                    list={practicesList} labelcols={4} component={RowSelectAutocompleteInput}
                                    disabled={!isNewProject} />
                            </Col>
                            {/* <Col md={4}>
                                <Field className="form-control-sm" formGroupClass=" mb-1" label="Product Category"
                                    name="CdgProjectCategoryId"
                                    onChange={value => setFieldValue("CdgProjectCategoryId", value['Id'])}
                                    list={categoriesList} labelcols={4} component={RowSelectAutocompleteInput}
                                    disabled={!isNewProject} />
                            </Col> */}
                            <Col md={4}>
                                <Field className="form-control-sm" formGroupClass=" mb-1" type="text" label="Selling Manager"
                                    name="SellingManagerFullName" disabled
                                    labelcols={4} component={RowInput} />
                            </Col>
                            <Col md={4}>
                                <Field className="form-control-sm" formGroupClass=" mb-1" type="text" label="Project Manager"
                                    name="ProjectManagerFullName" disabled
                                    labelcols={4} component={RowInput} />
                            </Col>
                        </Row>
                    </Collapse>
                </Col>
                <Col xs={12}>
                    <Row>
                        <Col xs={12} className="text-left">
                            <h5>Revenues</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <Field className="form-control-sm" formGroupClass=" mb-1" type="text"
                                   label="Planned Revenues (Time + IC)"
                                   name="CdgProjectPlannedInternalRevenues" labelcols={6} component={RowNumberFormatInput}
                                   disabled showuntouched/>
                        </Col>
                        <Col md={4}>
                            <Field className="form-control-sm" formGroupClass=" mb-1" type="text"
                                   label="Selling Price"
                                   onChange={value => setFieldValue("CdgProjectSellingPrice", value)}
                                   name="CdgProjectSellingPrice" labelcols={6} component={RowNumberFormatInput}
                                   disabled={!isNewProject || isProjectCodeId !== values["CdgProjectCodeId"]} showuntouched/>
                        </Col>
                        <Col md={4}>
                            <Field className="form-control-sm" formGroupClass=" mb-1" type="text"
                                   label="Actual Revenues (Time + IC)"
                                   name="CdgProjectEffectiveInternalRevenues" labelcols={6} component={RowNumberFormatInput}
                                   disabled showuntouched/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <Field className="form-control-sm" formGroupClass=" mb-1" type="text"
                                   label="Planned External Costs"
                                   onChange={value => setFieldValue("CdgProjectPlannedExternalRevenues", value)}
                                   name="CdgProjectPlannedExternalRevenues" labelcols={6}
                                   component={RowNumberFormatInput} disabled={!isNewProject || isProjectCodeId !== values["CdgProjectCodeId"]} showuntouched/>
                        </Col>
                        <Col md={4}>
                            <Row>
                                <Col xs={9}>
                                    <Field className="form-control-sm" formGroupClass=" mb-1" type="text"
                                    label="Planned Total Value"
                                    name="CdgProjectPlannedTotalRevenues" labelcols={6} component={RowNumberFormatInput}
                                    disabled showuntouched/>
                                </Col>
                                <Col xs={3}>
                                    <Field className="form-control-sm" formGroupClass=" mb-1" type="text"
                                        label="/"
                                        name="CdgProjectPlannedTotalRevenuesPerc" numtype="perc" labelcols={1} component={RowNumberFormatInput} labelClass="px-0"
                                        disabled showuntouched/>
                                </Col>
                            </Row>                                 
                        </Col>
                        <Col md={4}>
                            <Field className="form-control-sm" formGroupClass=" mb-1" type="text"
                                   label="Actual Other Revenues"
                                   name="CdgProjectEffectiveExternalRevenues" disabled labelcols={6} component={RowNumberFormatInput}
                                   showuntouched/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <Field className="form-control-sm" formGroupClass=" mb-1" type="text"
                                   label="Planned Gpos Activities"
                                   name="CdgProjectPlannedIntellectualRevenues" labelcols={6} component={RowNumberFormatInput} 
                                   showuntouched/>
                        </Col>
                        <Col md={4}>
                            <Row>
                                <Col xs={7}>
                                <Field className="form-control-sm" formGroupClass=" mb-1" type="text"
                                   label="Backlog"
                                   name="CdgProjectBacklog" disabled labelcols={5}
                                   component={RowNumberFormatInput} showuntouched/>
                                </Col>
                                <Col xs={5}>
                                <Field className="form-control-sm" formGroupClass=" mb-1" type="text"
                                   label="WIP"
                                   name="CdgProjectPlannedWIPRevenues" disabled labelcols={2}
                                   component={RowNumberFormatInput} showuntouched/>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={4}>
                            <Row>
                                <Col xs={9}>
                                <Field className="form-control-sm" formGroupClass=" mb-1" type="text"
                                   label="Actual Total Revenues"
                                   name="CdgProjectEffectiveTotalRevenues" disabled labelcols={6}
                                   component={RowNumberFormatInput} showuntouched/>
                                </Col>
                                <Col xs={3}>
                                <Field className="form-control-sm" formGroupClass=" mb-1" type="text"
                                    label="/"
                                    name="CdgProjectEffectiveTotalRevenuesPerc" numtype="perc" labelcols={1} component={RowNumberFormatInput} labelClass="px-0"
                                    disabled showuntouched/>
                                </Col>
                            </Row>                                
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="clearfix pt-3">
                <Col md={3} className="project-form-submit text-md-right pt-1">
                    <Button color="success" disabled={isSubmitting} onClick={handleSubmit}>Save</Button>
                </Col>
                <Col md={3} className="project-form-submit text-md-right pt-1">
                    <Button color="info" disabled={isSubmitting} onClick={doRefresh}>Refresh</Button>
                </Col>
                 {/* <Col md={4} className="text-md-left pt-1">
                     {values.Id && <Button disabled color="info" onClick={openSubmitModal}>Submit</Button>}
                </Col> */}
                <Col md={{size: 3, offset: 2}}>
                    <Link to={`${CONSULTANTS}/${values.Id}`}><Button color="secondary" >External Costs</Button></Link>
                </Col>
            </Row>
        </Form>
    )
}


ProjectDetailForm.propTypes = {
    projecttypeList: PropTypes.array,
    isNewProject: PropTypes.bool
};

ProjectDetailForm.defaultProps = {
    projecttypeList: [],
    isNewProject: false
};

export default ProjectDetailForm;
