import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

import { Button, Collapse, Navbar, NavbarBrand, NavbarToggler,  Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem  } from 'reactstrap';

import Me from '../Me';
import Download from '../Download';
import { HOME, UPLOAD, LOGIN, CHANGEPASSWORD, ALLOCATIONS, RECONCILIATION, AREA_REVENUES, AREA_RESOURCES, REPORT_PROJECT_LIST, REPORT_AREA_RESOURCE_TIME, IMPORT } from '../../navigation/routes';
import logo from '../../assets/logo.png';

import styles from  './Navbar.module.css';

const NavbarComponent = ({isAuth, isAreaManager, isPM, isFinance, isImportAdmin, doLogout, history, location: { pathname }}) => {
    const [isOpen, toggle] = useState(false);

    const onLogout = () => {
        doLogout();
        history.push(LOGIN);
    }

    return (
        <Navbar color="light" light expand="md">
            <NavbarBrand>
                <img src={logo} className={styles.logo} alt="Mercer" width={'120em'} />
            </NavbarBrand>
            <NavbarToggler onClick={() => toggle(!isOpen)} />
            <Collapse isOpen={isOpen} navbar>
                <Nav className="mr-auto" navbar>
                    <NavItem className={styles.left} onClick={() => toggle(false)}>
                        {isPM && <NavLink className={styles.menu} active={pathname === HOME} tag={Link} to={HOME}><i className="fa fa-tasks"></i><span className={styles.text}>My Projects</span></NavLink>}
                    </NavItem>
                    <NavItem className={styles.left} onClick={() => toggle(false)}>
                        {isAreaManager && <NavLink className={styles.menu} active={pathname === AREA_REVENUES} tag={Link} to={AREA_REVENUES}><i className="fa fa-eur"></i><span className={styles.text}>Area Revenues</span></NavLink>}
                    </NavItem>
                    <NavItem className={styles.left} onClick={() => toggle(false)}>
                        {isAreaManager && <NavLink className={styles.menu} active={pathname === AREA_RESOURCES} tag={Link} to={AREA_RESOURCES}><i className="fa fa-users"></i><span className={styles.text}>People</span></NavLink>}
                    </NavItem>
                    <NavItem className={styles.left} onClick={() => toggle(false)}>
                        {isAuth && <NavLink className={styles.menu} active={pathname === ALLOCATIONS} tag={Link} to={ALLOCATIONS}><i className="fa fa-tasks"></i><span className={styles.text}>Allocations List</span></NavLink>}
                    </NavItem>
                    <NavItem className={styles.left} onClick={() => toggle(false)}>
                        {isAuth && <NavLink className={styles.menu} active={pathname === REPORT_PROJECT_LIST} tag={Link} to={REPORT_PROJECT_LIST}><i className="fa fa-tasks"></i><span className={styles.text}>Project List</span></NavLink>}
                    </NavItem>
                    <NavItem className={styles.left} onClick={() => toggle(false)}>
                        {isAreaManager && <NavLink className={styles.menu} active={pathname === REPORT_AREA_RESOURCE_TIME} tag={Link} to={REPORT_AREA_RESOURCE_TIME}><i className="fa fa-users"></i><span className={styles.text}>Area Resources Time</span></NavLink>}
                    </NavItem>
                    <NavItem className={styles.left} onClick={() => toggle(false)}>
                        {isFinance && <NavLink className={styles.menu} active={pathname === RECONCILIATION} tag={Link} to={RECONCILIATION}><i className="fa fa-tasks"></i><span className={styles.text}>Codes Reconciliation</span></NavLink>}
                    </NavItem>
                    <NavItem className={styles.left} onClick={() => toggle(false)}>
                        {isImportAdmin && <NavLink className={styles.menu} active={pathname === IMPORT} tag={Link} to={IMPORT}><i className="fa fa-upload"></i><span className={styles.text}>Import Data</span></NavLink>}
                    </NavItem>
                    {isAuth && <UncontrolledDropdown className={styles.left} nav inNavbar>
                        <DropdownToggle nav caret className={styles.dropdown} >
                        <i className="fa fa-download"></i><span className={styles.text}>Download</span>
                        </DropdownToggle>
                        <DropdownMenu right>
                            {isPM && <>
                            <DropdownItem className={styles.list} tag="span" onClick={() => toggle(false)} ><Download type="projects" scope="own" label="My Projects" /></DropdownItem>
                            <DropdownItem divider />
                            </>}
                            <DropdownItem className={styles.list} tag="span" onClick={() => toggle(false)} ><Download type="time" scope="own" label="My Allocation" /></DropdownItem>
                            <DropdownItem className={styles.list} tag="span" onClick={() => toggle(false)} ><Download type="projects" scope="list" label="Project List" /></DropdownItem>
                            {isAreaManager && <>
                                <DropdownItem divider />
                                <DropdownItem className={styles.list} tag="span" onClick={() => toggle(false)} ><Download type="projects" scope="area" label="Area Project List" /></DropdownItem>
                                <DropdownItem className={styles.list} tag="span" onClick={() => toggle(false)} ><Download type="time" scope="area" label="Area Resource Time" /></DropdownItem>
                                <DropdownItem className={styles.list} tag="span" onClick={() => toggle(false)} ><Download type="resource" scope="area" label="Area Resource Detail" /></DropdownItem>
                                <DropdownItem className={styles.list} tag="span" onClick={() => toggle(false)} ><Download type="revenues" scope="area" label="Area Revenues" /></DropdownItem>
                            </>}
                        </DropdownMenu>
                    </UncontrolledDropdown>}
                    <NavItem onClick={() => toggle(false)}>
                        {false && <NavLink className={styles.menu} active={pathname === UPLOAD} tag={Link} to={UPLOAD}><i className="fa fa-upload"></i><span className={styles.text}>Data Upload</span></NavLink>}
                    </NavItem>
                </Nav>
                <Nav className="ml-auto" navbar>
                    {isAuth && <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret className={`${styles.dropdown} ${styles.dropdownRight}`}>
                                <Me />
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem className={styles.list} tag={Link} to={CHANGEPASSWORD} onClick={() => toggle(false)} >Change Password</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>}
                    <NavItem>
                        {isAuth && <Button color="primary" onClick={onLogout}>Logout</Button>}
                    </NavItem>
                </Nav>
            </Collapse>
        </Navbar>
    );
}

NavbarComponent.propTypes = {
    isAuth: PropTypes.bool,
    isPM: PropTypes.bool,
    isAreaManager: PropTypes.bool,
    isFinancier: PropTypes.bool,
    isImportAdmin: PropTypes.bool,
    doLogout: PropTypes.func.isRequired
};

NavbarComponent.defaultProps = {
    isAuth: false,
    isPM: false,
    isFinance: false,
    isImportAdmin: false,
    isAreaManager: false
};

export default withRouter(NavbarComponent);