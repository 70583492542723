import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ReportProjectListComponent from "./ReportProjectListComponent";
import { Container } from "reactstrap";
import Loader from "../Loader";
import { getReportProjectList } from "../../reducers";
import { columns } from "./utils";

function ReportProjectListContainer({ apigetData, reportProjectList }) {
  useEffect(() => {
    apigetData();
  }, [apigetData]);

  const getColumns = () => {
    return columns;
  }

  const getReportData = () => {
    const tableData = [...reportProjectList.data];
    return tableData;
  }

  if(!reportProjectList) return null;
  return (
    <Container fluid>
      {reportProjectList.fetched && (
        <ReportProjectListComponent projects={getReportData()} columns={getColumns()} />
      )}
      {reportProjectList.errors && reportProjectList.errors.length > 0 && (
        <p>{reportProjectList.errors}</p>
      )}
      <Loader isLoading={reportProjectList.fetching} />
    </Container>
  );
}

ReportProjectListContainer.defaultProps = {
  reportProjectList: [],
};

const mapStateToProps = ({ reportProjectList }) => {
  return {
    reportProjectList,
  };
};

const mapDispatchToProps = {
  apigetData: getReportProjectList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ReportProjectListContainer));
