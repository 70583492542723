import React from 'react';
import moment from 'moment';
import {MdRemoveCircleOutline, MdAddCircleOutline} from "react-icons/md";
import Select from 'react-select';

import { toNumber } from '../../helpers';


const plannedRevenues = row => {
    let planningWeeksSumm = 0;
    const periods = Object.keys(row.original).filter(key => key.match(/20[0-9][0-9]_[0-1][0-9]/));
    periods.forEach((value) => (planningWeeksSumm += toNumber(row.original[value]) || 0));
    return Math.round(planningWeeksSumm * 10000) / 10000;
};

const customCellStyle = {
    fontSize: '12px',
    textAlign: 'center'
};

const customSelectCellStyle = {
    option: (provided, state) => ({
        ...provided,
        textAlign: 'left'
    }),
    singleValue: (styles, {data}) => ({...styles, lineHeight: 1.3}),
};


const removeButtonCell = (props, removeWorkforce) => {
    const _click = () => {
        removeWorkforce(props.value);
    }

    return (
        <button type="button" onClick={_click} className="btn btn-default btn-sm">
            <MdRemoveCircleOutline size='1.3em'/>
        </button>
    );
};

const addButtonHeader = (AddWorkforce) => {
    const _click = () => {
        AddWorkforce();
    }
    return (
        <button type="button" onClick={_click} className="btn btn-default btn-sm">
            <MdAddCircleOutline size='1.3em'/>
        </button>
    );
};

const teamMemberCell = (props, updateWorkForceItem, memberList, activeMemberList) => {

    const _change = (memberObj) => {
        updateWorkForceItem(props.original.Id, "CdgWorkforceId", memberObj.Id);
    }

    return (
        <Select onChange={value => _change(value)}
                classNamePrefix={"my-custom-react-select"}
                styles={customSelectCellStyle}
                defaultValue={activeMemberList[0]} 
                options={activeMemberList} 
                getOptionValue={(option) => (option['Id'])}
                getOptionLabel={(option) => (option['LastName'] + ' ' + option['FirstName'] + ' (€ ' + option['CdgWorkforceDailyRate'] + ')')}
                value={memberList.find(elm => elm.Id === props.value)}/>
    );
}



 /*
const plannedDaysCell = (props, updateWorkForceItem) => {

    const _change = (e) => {
        updateWorkForceItem(props.original.Id, "CdgProjectWorkforcePlannedDays", e.target.innerHTML);
    }

    return (<div
        className="form-control"
        style={customCellStyle}
        contentEditable
        suppressContentEditableWarning
        onBlur={_change}
        onKeyPress={disableNewlines(_change)}
        value={toNumber(props.value)}
        dangerouslySetInnerHTML={{
            __html: toNumber(props.value)
        }}
    >
    </div>)
}
*/

const plannedRevCell = (props, memberList) => {

    const member = memberList.find(elm => elm.Id === props.original.CdgWorkforceId)
    const planningWeeksSumm = plannedRevenues(props);
    const value = Math.round(parseInt(member.CdgWorkforceDailyRate) * planningWeeksSumm);
    return (<div

        dangerouslySetInnerHTML={{
            __html: value
        }}
    >
    </div>)
}

const dailyRateCell = (props, memberList) => {

    const member = memberList.find(elm => elm.Id === props.original.CdgWorkforceId)

    return (<div dangerouslySetInnerHTML={{
            __html: member.CdgWorkforceDailyRate
        }}
    >
    </div>)
}

const effectivePlannedDaysCell = (props) => {

    const planningWeeksSumm = plannedRevenues(props);

    // className={planningWeeksSumm < props.original.CdgProjectWorkforcePlannedDays ? 'text-danger' : ''}
    return (<div dangerouslySetInnerHTML={{
                     __html: planningWeeksSumm
                 }}
    >
    </div>)
}


const WeekPlanningCell = ({props, Id, key1, updateWorkForceItem, row, setRow, keyId, setKeyId}) => {
    const [value, setValue] = React.useState(toNumber(props.value))

    function useOutsideAlerter(ref) {
        React.useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if(keyId === key1 && row === Id){
                        updateWorkForceItem(Id, key1, divref.current.value);
                        setRow(null)
                        setKeyId(null)
                    }
                }
            }
    
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const divref = React.useRef();

    const _change = (e) => {
        updateWorkForceItem(Id, key1, value);
    }

    const remember = (e) => {
        setKeyId(key1); 
        setRow(Id);
    }
    const disableNewlines = change => event => {
        const keyCode = event.keyCode || event.which;
        if (keyCode === 13) {
            event.returnValue = false;
            change(event);
            if (event.preventDefault) event.preventDefault();
        }
    }

    const handleKeyDown = change => e => {
        if (e.shiftKey && e.key === 'Tab') {
            e.returnValue = false;
            change(e);
            if (e.preventDefault) e.preventDefault();
            let head = keyId.substring(0, 4);
            let tail = parseInt(keyId.substring(5, 8));
            let resTail;
            let newKeyId;
            if(tail<11 && tail>0){
              const aux = tail-1;
              resTail='0'+aux;
              newKeyId=head+'_'+resTail;
            }
            else if(tail>=11){
                resTail=tail-1;
                newKeyId=head+'_'+resTail;
            } else {
                head = parseInt(head);
                head--;
                newKeyId=head+'_11';
            }
            setKeyId(newKeyId)
          }
        else if(e.key === "Tab" || e.key === "Enter") {
          e.returnValue = false;
          change(e);
          if (e.preventDefault) e.preventDefault();
          let head = keyId.substring(0, 4);
          let tail = parseInt(keyId.substring(5, 8));
          let resTail;
          let newKeyId;
          if(tail<9){
            const aux = tail+1;
            resTail='0'+aux;
            newKeyId=head+'_'+resTail;
          }
          else if(tail>=9 && tail<=10){
              resTail=tail+1;
              newKeyId=head+'_'+resTail;
          } else {
              head = parseInt(head);
              head++;
              newKeyId=head+'_00';
          }
          setKeyId(newKeyId)
        }
      }
    
    const handleChange = (e)=>{
        setValue(e.target.value)
    }
    useOutsideAlerter(divref);

    React.useEffect(()=>{
        if(keyId === key1 && row === Id){
            divref.current.focus();
            divref.current.select();
        }
    },[row, keyId])

    React.useEffect(()=>{
        setValue(toNumber(props.value))
    },[props.value])

    return (
        <input type='text'
        ref={divref}
        value={value}
        className="form-control"
        style={customCellStyle}
        onKeyDown={handleKeyDown(_change)} 
        onKeyPress={disableNewlines(_change)}
        onBlur={_change}
        onChange={handleChange} 
        onDoubleClick={remember}
        onClick={remember}
        onFocus={remember}
        ></input>)
}



const WorkForceDefinition = (
    addWorkforce,
    updateWorkForceItem,
    removeWorkForce,
    memberList,
    activeMemberList,
    data,
    row,
    setRow,
    keyId,
    setKeyId
) => {

    let columns = [
        {
            Header: addButtonHeader(addWorkforce),
            accessor: 'Id',
            minWidth: 50,
            Cell: props => removeButtonCell(props, removeWorkForce),
            filterable: false,
            sortable: false,
            fixed: "left",
        },
        {
            Header: 'Team Member',
            accessor: 'CdgWorkforceId',
            foldable: true,
            minWidth: 250,
            maxWidth: 350,
            style: {"overflow": 'visible'},
            Cell: props => teamMemberCell(props, updateWorkForceItem, memberList, activeMemberList),
            fixed: "left",
        },
        {
            Header: 'Daily Rate',
            accessor: 'CdgWorkforceDailyRate',
            // foldable: true,
            // minWidth: 14,
            Cell: props => dailyRateCell(props, memberList),
            show: false
        },
        {
            Header: 'Planned Days',
            accessor: 'CdgProjectWorkforcePlannedDays',
            // PM 20190727 nascosto e duplicato da Monthly Planned per evitare modifiche nei calcoli
            // foldable: true,
            // minWidth: 15,
            // Cell: props => plannedDaysCell(props, updateWorkForceItem),
            show: false,
            Cell: effectivePlannedDaysCell,
            fixed: "left",
        },
        {
            Header: 'Total Planned',
           // accessor: 'CdgWorkforceEffectivePlannedDays',
            foldable: true,
            minWidth: 110,
            Cell: effectivePlannedDaysCell,
            fixed: "left",
        },
        {
            Header: 'Planned Rev.',
            accessor: 'CdgWorkforcePlannedRev',
            foldable: true,
            minWidth: 80,
            Cell: props => plannedRevCell(props, memberList),
            fixed: "left",
        },
        {
            Header: 'Actual Days',
            accessor: 'CdgProjectWorkforceEffectiveDays',
            foldable: true,
            minWidth: 80,
            fixed: "left",
        },
        {
            Header: 'Actual Rev',
            accessor: 'CdgProjectWorkforceEffectiveRevenues',
            foldable: true,
            minWidth: 80,
            fixed: "left",
        },
        {
            Header: 'I.C.',
            accessor: 'CdgProjectWorkforceIntellectualCapitals',
            foldable: true,
            minWidth: 80,
            fixed: "left",
        },

    ];
    let periods = [];

    if (data.length > 0) {
        periods = Object.keys(data[0]).filter(key => key.match(/20[0-9][0-9]_[0-1][0-9]/));
    }
    periods.forEach((key) => {
        let month = moment(key, "YYYY-MM").subtract(-1, "month")
            .startOf("month").format('MMM-YY') + ' (d)';
        let dataKey = moment(key, "YYYY-MM").subtract(-1, "month")
        .startOf("month")._d;
        const currMonth = new Date().getMonth();
        const currYear = new Date().getFullYear();
        let monthKey;
        let yearKey;
        //when key is YYYY-00 and we subtract -1 we get invalid date, it appear just for January
        if(month==='Invalid date (d)'){
            const aux = key.substring(2, 4);
            month = 'Jan-'+aux+' (d)';
            monthKey = 0;
            yearKey = parseInt(key.substring(0, 4));
        } else {
            monthKey = dataKey.getMonth()
            yearKey = dataKey.getFullYear()
        }
         const headerStyle =(currMonth===monthKey && currYear===yearKey)? {
            backgroundColor:'#FFFF8F',
          }:{}

        columns.push(
            {
                Header: month,
                headerStyle: headerStyle,
                accessor: key,
                minWidth: 80,
                maxWidth: 80,
                Cell: (props) => {
                    return (
                      <WeekPlanningCell
                        props={props}
                        Id={props.original.Id}
                        key1={key}
                        updateWorkForceItem={updateWorkForceItem}
                        row={row}
                        setRow={setRow}
                        keyId={keyId}
                        setKeyId={setKeyId}
                      />
                    );
                },
                // foldable: true,
                // minWidth: 100,
            }
        )
    })

    return columns;
};

export default WorkForceDefinition;