import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Row, Col, Button, Form, Alert} from 'reactstrap';
import { RowFileInput, RowSelectInput } from '../FormElements';

import styles from  './ImportFile.module.css';


const ImportFileComponent = ({
  values,
  touched,
  errors,
  status,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  isSubmitting,
  isValid,
  errore,
  success,
  importList
}) => {
  return (  
    <Form onSubmit={handleSubmit} >
      <Row className={styles.form}>
        <Col md={3} className={styles.content}>
          <Field 
            className="form-control-sm" 
            formGroupClass=" mb-1" 
            type="text" 
            label=""
            name="importType"
            labelcols={0}
            list={['Select Import Type', ...importList]}
            component={RowSelectInput} 
            autoFocus 
          />
        </Col>
        <Col md={6} className={styles.content}>
          <Field 
            className="form-control-sm" 
            formGroupClass=" mb-1" 
            type="file" 
            label=""
            name="file"
            labelcols={0}
            component={RowFileInput}
            onFileLoaded={file => setFieldValue('file', file)}
          />
        </Col>
        <Col md={3} className={styles.content}>
          <Button color="success" type="submit" disabled={isSubmitting || !isValid}>Import</Button>
        </Col>
      </Row>
        {errore && (<Alert color="danger"> {errore.messaggio + (errore.response?.data?.Error || '') } </Alert>)}
        {success && (<Alert color="success"> {success.message} </Alert>)}
    </Form>
  );
}


ImportFileComponent.propTypes = {
  errore: PropTypes.object,
  success: PropTypes.object,
  importList: PropTypes.arrayOf(PropTypes.string),
}

ImportFileComponent.defaultProps = {
  errore: null,
  success: null,
  importList: [],
};

export default ImportFileComponent;