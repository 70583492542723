import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import api from "../../api";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Loader from "../Loader";
import ProjectDetailForm from "../ProjectDetailForm";
import ProjectWorkforceContainer from "../ProjectWorkforce/";

import {
  getProjectDetail,
  getProjectWorkforce,
  getWorkforce,
  getManagers,
  getCategories,
  getPractices,
  getCodes,
  getProjectDetailAfterUpdate
} from "../../reducers";
import * as routes from "../../navigation/routes";
import { Alert } from "reactstrap";

const DATE_CHANGE = "Planning outside new date range will be deleted.";
const SUBMIT_PROJECT = "Are you sure you want to submit the project?";

const showErrors = (entities) =>
  entities.map((entity, idx) =>
    entity.errors ? (
      <p key={idx}>{((entity.errors.response || {}).data || {}).Error}</p>
    ) : (
      ""
    )
  );

function ProjectDetailContainer(props) {
  const [size, setSize] = React.useState(10);
  const [alert, setAlert] = useState({
    messge: "",
    visible: false,
    color: "warning",
  });
  const [modal, setModal] = useState({
    title: "",
    messge: "",
    visible: false,
    onApprove: null,
  });

  useEffect(() => {
    const id = match.params.id ? parseInt(match.params.id) : null;;
    LoadData(id);

    if (id) {
      const interval = setInterval(()=>{
        props.getProjectDetail(id)
      },5000) // TODO PM 5000
      return () => {
        clearInterval(interval);
      };
    }
  }, []);

  function LoadData(id) {
    const {
      getWorkforce,
      getManagers,
      getCategories,
      getPractices,
      getCodes,
    } = props;
    
    if (id) {
      getCodes(id);
      loadWorkForce();
      getWorkforce(id);
      getManagers(id);
    }
    getCategories();
    getPractices();
  }

  const loadWorkForce = useCallback(()=> {
    const { getProjectWorkforce, match, getProjectDetail } = props;
    const id = parseInt(match.params.id);

    if (id) {
      getProjectDetail(id);

      getProjectWorkforce(id);
    }
  },[props.match.id])

  const onAlertDismiss = useCallback(()=> {
    setAlert({
      messge: "",
      visible: false,
    });
  },[alert])

  const onAlertOpen = useCallback((message, color = "warning") =>{
    setAlert({
      messge: message,
      visible: true,
      color,
    });
  },[alert])

  const openDateModal = useCallback(()=> {
    setModal((prevState) => ({
      title: "Date Changed",
      messge: DATE_CHANGE,
      visible: true,
      onApprove: closeModal,
    }));
  },[modal])

  const openSubmitModal = useCallback(()=> {
    setModal((prevState) => ({
      title: "Submit Project",
      messge: SUBMIT_PROJECT,
      visible: true,
      onApprove: submitProject,
    }));
  },[modal])

  const closeModal = useCallback(()=> {
    setModal((prevState) => ({
      title: "",
      messge: "",
      visible: false,
      onApprove: null,
    }));
  },[modal])

  async function submitProject() {
    closeModal();
    try {
      let id = props.project.data.Id;
      await api.submitProject(id);
      onAlertOpen("The project has been submitted", "success");
      setTimeout(onAlertDismiss, 3000);
    } catch (error) {
      onAlertOpen("Can't submit project");
    }
  }

  async function submitProjectDetails(data, { setSubmitting }) {
    if (data.Id) {
      try {
        await api.updateProject(data);
        setSubmitting(false);
        LoadData();
      } catch (error) {
        onAlertOpen("Can't update project");
      }
    } else {
      try {
        await api.addProject(data);
        props.history.push(routes.HOME);
      } catch (error) {
        onAlertOpen("Can't add project");
      }
    }
  }

  const {
    project,
    projectWorkforce,
    workforce,
    managers,
    categories,
    practices,
    codes,
    match,
  } = props;
  const id = match.params.id ? parseInt(match.params.id) : null;
  const projectData = id ? project.data : "";
  const managersData = id ? managers.data : [];
  const IdProject = projectData.Id;
  let isLoading =
    managers.fetching ||
    projectWorkforce.fetching ||
    workforce.fetching ||
    categories.fetching ||
    practices.fetching ||
    codes.fetching;

  return (
    <Container fluid>
      <Alert
        className="sticky-top pb-2"
        color={alert.color}
        isOpen={alert.visible}
        toggle={onAlertDismiss}
      >
        <h6>{alert.messge}</h6>
      </Alert>

      <Row>
        <Col md="12" className="pt-2 pb-2">
          {categories.fetched && practices.fetched && (codes.fetched || !id) && (
            <ProjectDetailForm
              openDateModal={openDateModal}
              openSubmitModal={openSubmitModal}
              doSubmit={submitProjectDetails}
              doRefresh={() => props.getProjectDetail(id)}
              isNewProject={!id}
              project={projectData}
              managers={managersData}
              categories={categories.data}
              practices={practices.data}
              codes={id ? codes.data : []}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col md="12" className="mt-2 pt-2 pb-2">
          {id && projectWorkforce.fetched && workforce.fetched && (
            
              <ProjectWorkforceContainer
                openWarning={onAlertOpen}
                project={IdProject}
                getUpdatedWorForce={loadWorkForce}
                projectWorkforce={projectWorkforce.data}
                workforce={workforce.data}
                getProjectDetail={props.getProjectDetail}
                getProjectDetailAfterUpdate={props.getProjectDetailAfterUpdate}
                size={size}
                setSize={setSize}
              />
          )}

          {showErrors([
            project,
            projectWorkforce,
            workforce,
            managers,
            categories,
            practices,
            codes,
          ])}
        </Col>
      </Row>
      <Loader isLoading={isLoading} />

      <Modal isOpen={modal.visible} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>{modal.title}</ModalHeader>
        <ModalBody>
          <h6 className="text-center">{modal.messge}</h6>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => modal.onApprove()}>
            Continue
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
}

ProjectDetailContainer.propTypes = {
  project: PropTypes.object,
};

ProjectDetailContainer.defaultProps = {
  project: {},
};

const mapStateToProps = ({
  projectDetail,
  projectWorkforce,
  workforce,
  managers,
  categories,
  practices,
  codes,
}) => {
  return {
    project: projectDetail,
    projectWorkforce,
    workforce,
    managers,
    categories,
    practices,
    codes,
  };
};

const mapDispatchToProps = {
  getProjectDetail,
  getProjectWorkforce,
  getWorkforce,
  getManagers,
  getCategories,
  getPractices,
  getCodes,
  getProjectDetailAfterUpdate
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProjectDetailContainer));
