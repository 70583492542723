import { includesFilterMethod } from '../../helpers/filters';
import { numberSortMethod } from '../../helpers/sort';

const headerStyle = { fontWeight: 'bold' };

const leftAlign = row => <div style={{textAlign: 'left'}}>{row.value}</div>

const mapper = cols => cols.map(col => ({...col, headerStyle }));


const COLUMNS = [
  { Header: "Row", accessor: "RowNumber", fixed: "left", minWidth: 50, sortMethod: numberSortMethod },
  { Header: "Error", accessor: "RowExtendedError", minWidth: 300, Cell: leftAlign, filterable: true, filterMethod: includesFilterMethod },

];

export const setColumns = () => mapper(COLUMNS);
