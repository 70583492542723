/**
 * Form row input
 *
 * @see https://github.com/shoaibkhan94/reactstrap-formik
 */

import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { Col, FormFeedback, FormGroup, Label, Input } from 'reactstrap';

const customCellStyle = {
    option: (provided, state) => ({
        ...provided,
        textAlign: 'left'
    }),
    singleValue: (styles, { data }) => ({ ...styles, lineHeight: 1.3, color: 'black' }),
};

const RowSelectAutocompleteInput = ({
  field: {...fields},
  form: {touched, errors},
  labelcols, 
  label,
  list,
  formGroupClass,
  onChange,
  defaultValue,
  disabled,
  ...props
}) =>
  <FormGroup row className={formGroupClass}>
    <Label xs={labelcols} for={fields.name} className="label-color text-left">{label}</Label>
    <Col xs={12 - labelcols}>
    <Select styles={customCellStyle} onChange={onChange} isDisabled={disabled}
      defaultValue={defaultValue} options={list} getOptionValue={(option) => (option['id'])} 
      getOptionLabel={(option) => (option['desc'])} value={list.find(elm => elm.id === fields.value)} />
      <Input hidden invalid={(touched[fields.name]) && errors[fields.name]}/>
      <FormFeedback >{errors[fields.name]}</FormFeedback>
    </Col>
  </FormGroup>

RowSelectAutocompleteInput.propTypes = {
  field: PropTypes.object.isRequired, // da Formik: name
  form: PropTypes.object.isRequired,  // da Formik: touched, errors
  label: PropTypes.string.isRequired, // etichetta
  labelcols: PropTypes.number,        // num colonne per etichetta
  list: PropTypes.oneOfType([         // lista option: array di stringhe o di oggetti { id, desc }
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      desc: PropTypes.string
    }))
  ]),
  formGroupClass: PropTypes.string,
  disabled: PropTypes.bool
 };

 RowSelectAutocompleteInput.defaultProps = { 
  labelcols: 3,
  formGroupClass: "",
  disabled: false
 };

export default RowSelectAutocompleteInput;
