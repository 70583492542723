import { MultiSelect } from "react-multi-select-component";
import { map, uniqBy, get, isEmpty } from "lodash";
import React, { useState } from "react";

const CustomItemRenderer = ({
  checked,
  option,
  onClick,
  disabled,
  filterData,
}) => (
  <div className={`item-renderer ${disabled && "disabled"}`}>
    <input
      type="checkbox"
      onChange={onClick}
      checked={checked}
      tabIndex={-1}
      disabled={disabled}
    />
    <span>{get(filterData, option.label, option.label)}</span>
  </div>
);

export function FrontendComponentsMultipleSelection({
  data,
  setFilter,
  placeholder,
  filterData,
  setFlag,
  value,
  setSelectedData
}) {
  const [selected, setSelected] = useState([]);
  const options = React.useMemo(() => {
    const removedDuplciates = uniqBy(data, 'id');
    const options = map(removedDuplciates, (row) => ({
        label: row["value"],
        value: row["id"],
      }));
    
    return options;
  }, [data]);

  const updateFilter = (values) => {
    if (values && values.length === 0) {
      setSelectedData([]);
    }
    setSelected(values);
    const filterVlaues = map(values, "value");
    setFilter(isEmpty(filterVlaues) ? undefined : filterVlaues);
  };
  // Render a multi-select box
  const customValueRenderer = (selected, _options) =>
    selected.length ? `${placeholder} (${selected.length})` : placeholder;

  return (
    <div onClick={()=>setFlag(value)}>
      <MultiSelect
        options={options}
        value={selected}
        onChange={updateFilter}
        labelledBy="Select"
        valueRenderer={customValueRenderer}
        ItemRenderer={(props) => (
          <CustomItemRenderer {...props} filterData={filterData} />
        )}
        overrideStrings={get(filterData, "filterLanguages", {})}
      />
    </div>
  );
}
export default FrontendComponentsMultipleSelection;
