export const HOME = '/';
export const CHANGEPASSWORD = '/changepassword';
export const LOGIN = '/login';
export const UNAUTHORIZED = '/403';
export const PROJECT = '/project';
export const PROJECT_ADD = '/project/add';
export const UPLOAD = '/upload';
export const ALLOCATIONS = '/allocations';
export const CONSULTANTS = '/consultants';
export const RECONCILIATION = '/reconciliation';
export const RECONCILE_PROJECT = '/reconcileproject';
export const AREA_REVENUES = '/arearevenues';
export const AREA_DETAIL_REVENUES = '/areadetailrevenues';
export const AREA_RESOURCES = '/arearesources';
export const AREA_RESOURCE_DETAIL = '/arearesourcedetail';
export const REPORT_PROJECT_LIST = '/reportprojectlist';
export const REPORT_AREA_RESOURCE_TIME = '/reportarearesourcetime';
export const IMPORT = '/import';