import React, { useEffect } from "react";
import { connect } from "react-redux";
// import moment from "moment";
import AreaResourcesComponent from "./AreaResourcesComponent";
import { setTotalColumns } from "./utils";
import { Container } from "reactstrap";
import Loader from "../Loader";
import { getAreaResourceTime } from "../../reducers";

// const curMonth = parseInt(moment().format("M")) -1;

function AreaResourcesContainer({ reportAreaResourceTime, getAreaResourceTime }) {
  useEffect(() => {
    getAreaResourceTime();
  }, [getAreaResourceTime]);

  const getAreaResourcesData = () => {
    const tableData = [...reportAreaResourceTime.data];
    return tableData;
  }

  if(!reportAreaResourceTime) return null;
  return (
    <Container fluid>
      {reportAreaResourceTime.fetched && (
        <AreaResourcesComponent 
          data={getAreaResourcesData()} 
          columns={setTotalColumns()} 
        />
      )}
      {reportAreaResourceTime.errors && reportAreaResourceTime.errors.length > 0 && (
        <p>{reportAreaResourceTime.errors}</p>
      )}
      <Loader isLoading={reportAreaResourceTime.fetching} />
    </Container>
  );
}

AreaResourcesContainer.defaultProps = {
  reportAreaResourceTime: [],
};

const mapStateToProps = ({ reportAreaResourceTime }) => {
  return {
    reportAreaResourceTime,
  };
};

const mapDispatchToProps = {
  getAreaResourceTime,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AreaResourcesContainer);
