import { combineReducers } from 'redux';

import projectsReducer, { getProjects } from './projectsReducer';
import projectDetailReducer, { getProjectDetail, getProjectDetailAfterUpdate } from './projectDetailReducer';
import projectWorkforceReducer, { getProjectWorkforce } from './projectWorkforceReducer';
import workforceReducer, { getWorkforce } from './workforceReducer';
import managersReducer, { getManagers } from './managersReducer';
import categoriesReducer, { getCategories } from './categoriesReducer';
import practicesReducer, { getPractices } from './practicesReducer';
import codesReducer, { getCodes, getUnassignedCodes } from './codesReducer';
import lastUpdateReducer, { getLastUpdate } from './lastupdateReducer';
import allocationReducer, { getAllocations } from './allocationReducer';
import consultantsReducer, { getConsultants } from './consultantsReducer';
import dummyProjectsReducer, { getDummyProjects, getDummyProject } from './dummyProjectsReducer';
import areaRevenuesReducer, { getAreaRevenues } from './areaRevenuesReducer';
import areaResourcesReducer, { getAreaResources } from './areaResourcesReducer';
import reportProjectListReducer, { getReportProjectList } from './reportProjectListReducer';
import reportAreaResourceTimeReducer, { getAreaResourceTime } from './reportAreaResourceTimeReducer';
import importExportReducer, {getImportStatus } from './importExportReducer';

export default combineReducers({
  projects: projectsReducer,
  projectDetail: projectDetailReducer,
  projectWorkforce: projectWorkforceReducer,
  workforce: workforceReducer,
  managers: managersReducer,
  categories: categoriesReducer,
  practices: practicesReducer,
  codes: codesReducer,
  lastUpdate: lastUpdateReducer,
  allocations: allocationReducer,
  consultants: consultantsReducer,
  dummyProjects: dummyProjectsReducer,
  areaRevenues: areaRevenuesReducer,
  areaResources: areaResourcesReducer,
  reportProjectList: reportProjectListReducer,
  reportAreaResourceTime: reportAreaResourceTimeReducer,
  importExport: importExportReducer
});

export {
  getAllocations,
  getProjects,
  getProjectDetail,
  getProjectWorkforce,
  getWorkforce,
  getManagers,
  getCategories,
  getPractices,
  getCodes,
  getUnassignedCodes,
  getLastUpdate,
  getProjectDetailAfterUpdate,
  getConsultants,
  getDummyProjects,
  getDummyProject,
  getAreaRevenues,
  getAreaResources,
  getReportProjectList,
  getAreaResourceTime,
  getImportStatus
};