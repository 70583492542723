/**
 * Form row input
 *
 * @see https://github.com/shoaibkhan94/reactstrap-formik
 */

import React from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import { Col, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';

const RowDatePickerInput = ({
    field: { ...fields },
    form: { touched, errors },
    labelcols,
    showuntouched,
    label,
    formGroupClass,
    ...props
}) => {
    let date = moment(fields.value);
    date = date.isValid() ? date.format("DD-MM-YYYY") :  moment().format("DD-MM-YYYY");
    return (
        <FormGroup row className={formGroupClass}>
            <Label xs={labelcols} for={fields.name} className="label-color text-left">{label}</Label>
            <Col xs={12 - labelcols} className="text-right">
                {/* <Input {...props} {...fields} invalid={(showuntouched || touched[fields.name]) && !!errors[fields.name]}/> */}
                <DatePicker
                    value={date}
                    dateFormat="DD-MM-YYYY"
                    {...props}
                />
                <Input hidden invalid={(touched[fields.name]) && errors[fields.name]}/>
                <FormFeedback >{errors[fields.name]}</FormFeedback>
            </Col>
        </FormGroup>
    )
}


RowDatePickerInput.propTypes = {
    field: PropTypes.object.isRequired, // da Formik: name
    form: PropTypes.object.isRequired,  // da Formik: touched, errors
    label: PropTypes.string.isRequired, // etichetta
    labelcols: PropTypes.number,        // num colonne per etichetta
    showuntouched: PropTypes.bool,       // mostra errore anche campo non touched (es. numerici)
    formGroupClass: PropTypes.string
};

RowDatePickerInput.defaultProps = {
    labelcols: 3,
    formGroupClass: "",
    showuntouched: false
};

export default RowDatePickerInput;
