import React from "react";

import styles from  '../Projects/Projects.module.css';
import { Row, Col } from "reactstrap";
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import ExportButton from "./ExportContainer";
import ImportFileForm from "../ImportFileForm";
import Loader from "../Loader";

import "react-table/react-table.css";
import "react-table-hoc-fixed-columns/lib/styles.css";

const Title = ({StatusDescription, RowTotal, RowFailed, RowCompleted, RowProcessed, ImportType, ImportDate, batchid}) => (
    <Col className="mt-3 mb-3">
      <h5>{`Batch: ${batchid} ${ImportType} ${ImportDate.substring(0, 19).replace('T', ' ')} - Status: ${StatusDescription} - Total Rows: ${RowTotal}, Failed Rows: ${RowFailed}, Completed Rows: ${RowCompleted}, Processed Rows: ${RowProcessed}`}</h5>
    </Col>
)

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const areEqual = (prevProps, nextProps) => prevProps.batchid === nextProps.batchid && 
  prevProps.data.length === nextProps.data.length &&
  (prevProps.data[0] || {}).ImportStatus === (nextProps.data[0] || {}).ImportStatus;

function ImportExportComponent({ data, columns, isLoading, fetched, batchid }) {
  const title = "Import Data";

  const [first, ...rest] = data;

  return (
    <>
      <Row className={styles.title}>
        <Col md={2}>
          <h3 className={styles.header}>
            <i className="fa fa-upload"></i>
            <span className={styles.text}>{title}</span>
          </h3>
        </Col>
        <Col md={8}>
          <ImportFileForm />
        </Col>
        <Col md={2}>
          <ExportButton label="Export Resources" name="resources" />
        </Col>
      </Row>
      <Row>
        {fetched && <Col md={12}>
          {data.length > 0 && <Title {...first} batchid={batchid} />}
          <ReactTableFixedColumns
            columns={columns}
            data={rest}
            defaultPageSize={10}
            className="-striped -highlight"
            style={{ width: "100%", overflow: "auto" }}
          />
        </Col>}
        <Loader isLoading={isLoading} />
      </Row>
      
    </>
  );
}

export default React.memo(ImportExportComponent, areEqual);
