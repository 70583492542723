export const startFilterMethod =  (filter, row, column) => {
  const id = filter.pivotId || filter.id;
  const value = String(filter.value);
  return row[id] !== undefined ? String(row[id]).toLowerCase().startsWith(value.toLowerCase()) : true
};

export const includesFilterMethod =  (filter, row, column) => {
  const id = filter.pivotId || filter.id;
  const value = String(filter.value);
  if (row[id] && typeof(row[id]) ==="object") {
      return  row[id] !== undefined ? String(Object.values(row[id])).toLowerCase().includes(value.toLowerCase()) : true
  }
  return row[id] !== undefined ? String(row[id]).toLowerCase().includes(value.toLowerCase()) : true
};