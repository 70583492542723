import React, {useContext} from 'react';
import {Route, Redirect} from 'react-router-dom';

import { AuthContext } from '../authentication';
import {LOGIN, UNAUTHORIZED} from './routes';

const verifyClaim = (claim, {isFinance, isImportAdmin, isAreaManager}) => {
  if (claim === 'finance')
    return isFinance;
  if (claim === 'importAdmin')
    return isImportAdmin;
  if (claim === 'areaManager')
    return isAreaManager;
  return true;
};

const AuthRoute = ({component: Component, claim, ...rest}) => {
  
  const {isAuth, isFinance, isImportAdmin, isAreaManager} = useContext(AuthContext);
  const condition = verifyClaim(claim, {isFinance, isImportAdmin, isAreaManager});

  // tutto ok
  if (isAuth && condition)
    return (
      <Route 
        render={props => <Component {...props} />} 
        {...rest}
      /> 
    );

  // autenticazione da fare
  if (!isAuth)
  return (
    <Route 
      render={props => <Redirect to={{pathname:LOGIN, state: {from: props.location}}} />} 
      {...rest}
    /> 
  );

  // autenticato ma livello insufficiente
  return (
    <Route 
      render={props => isAuth ? 
        <Component {...props} /> : 
        <Redirect to={{pathname: UNAUTHORIZED}} />} 
      {...rest}
    /> 
  );
};

AuthRoute.propTypes = {};

AuthRoute.defaultProps = {};

export default AuthRoute;