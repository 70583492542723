import api from '../api';

const API = 'dummyprojects';
const PENDING = `${API}_PENDING`;
const FULFILLED = `${API}_FULFILLED`;
const REJECTED = `${API}_REJECTED`;
const FOUND = `${API}_FOUND`;

const initialState = {
  data: [],
  currentId: null,
  fetching: false,
  fetched: false,
  errors: []
};

const reducer = (state = initialState, action = {}) => {
  const { type, payload, meta } = action;
  switch (type) {
    case PENDING: return {...state, fetching: true, fetched: false};
    case REJECTED: return {...initialState, errors: payload};
    case FULFILLED: return { data: [...payload.data], currentId: meta, fetching: false, fetched: true};
    case FOUND: return state; // just for clarity
    default: return state;
  }
}
export default reducer;

// Action Creator
export const getDummyProjects = () => ({
  type: API,
  payload: api.getDummyProjects(),
});

export const getDummyProject = (_) => {
  return (dispatch, getState) => {
    const { dummyProjects } = getState();
    if (dummyProjects.fetched) dispatch({ type: FOUND});
    else dispatch(getDummyProjects());
  };
}
