import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from "react-csv";

import api from '../../api';

import styles from  './Download.module.css';

class DownloadContainer extends Component {
  csvLink = createRef();
  state = { data: [] };

  getData = async (menu) => {
    const { type, scope } = this.props;
    try {
      const { data } = await api.getDownloadData(type, scope);
      // click the CSVLink component to trigger the CSV download
      this.setState({ data }, () => this.csvLink.current.link.click());
    } catch (error) {
      console.error(error);
    }

  }

  render(props) {
    const { data } = this.state;
    return(
      <>
        <button onClick={this.getData} {...props} className={styles.button} >
          {this.props.label}
        </button>
        <CSVLink data={data} filename={this.props.label} separator={";"} ref={this.csvLink} visible="false" target="_blank" />
      </>
    );
  }
}

DownloadContainer.propTypes = {
  type: PropTypes.string.isRequired,
  scope: PropTypes.string.isRequired,
  label: PropTypes.string
};

DownloadContainer.defaultProps = {
  label: 'Download'
};

export default DownloadContainer;
