import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Container } from 'reactstrap';
import ChangePasswordComponent from './ChangePasswordComponent';
import Loader from '../Loader';

import { errors } from '../FormElements';
import api from '../../api';
const { REQUIRED, MATCHPWD, PWDSAVED } = errors;

const initialValues = {
  oldPassword: '',
  newPassword: '',
  repeatPassword: ''
};

const formSchema = Yup.object().shape({
  oldPassword: Yup.string().required(REQUIRED),
  newPassword: Yup.string().required(REQUIRED),
  repeatPassword: Yup.string()
    .required(REQUIRED)
    .test('pwd-match', MATCHPWD, function(value) { 
      return this.parent.newPassword === value;
    })
});

// cross validation
// const validate = ({newPassword, repeatPassword}) => {
//   let errors = {};
//   if (newPassword && repeatPassword && newPassword !== repeatPassword) {
//     errors.repeatPassword = MATCHPWD;
//   }
//   return errors;
// };

const ChangePasswordContainer = ({location}) => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();

  const doChangePassword = async ({ oldPassword, newPassword }, { setSubmitting, setStatus }) => {

    try {
      setError();
      setLoading(true);
      await api.changePassword(oldPassword, newPassword);
      setSubmitting(false);
      setLoading(false);
      setSuccess({ message: PWDSAVED });
    } catch (er) {
      setLoading(false);
      setSubmitting(false);
      setError(
        { ...er,
          messaggio: 'Error: '
        });
      setSuccess();
    }
  };
  
  return (
    <Container>
      <Loader isLoading={isLoading} />
      {!isLoading && <Formik
        initialValues={initialValues}
        validationSchema={formSchema}
        // validate={validate}
        onSubmit={doChangePassword}
        render={props => <ChangePasswordComponent {...props} errore={error} success={success} />}
      />}
    </Container>
  );
};

ChangePasswordContainer.propTypes = {};

ChangePasswordContainer.defaultProps = {};

export default ChangePasswordContainer;