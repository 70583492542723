import api from '../api';

const API = 'projectDetail';
const PENDING = `${API}_PENDING`;
const FULFILLED = `${API}_FULFILLED`;
const REJECTED = `${API}_REJECTED`;

const initialState = {
  data: {},
  currentId: null,
  fetching: false,
  fetched: false,
  errors: []
};

export default (state = initialState, action = {}) => {
  const { type, payload, meta } = action;
  switch (type) {
    case PENDING: return {...state, fetching: true, fetched: false};
    case REJECTED: return {...initialState, errors: payload};
    case FULFILLED: return { data: payload.data, currentId: meta, fetching: false, fetched: true};
    default: return state;
  }
}

// Action Creator
export const getProjectDetail = id => ({
  type: API,
  payload: api.getProjectDetail(id),
  meta: id 
});

export const getProjectDetailAfterUpdate = (updatedForceItem, id) => {
  return  (dispatch) => {
    try{
      api.updateWorkForce(updatedForceItem);
      
      dispatch(getProjectDetail(id))
    } catch(err){
      console.log(err)
    }
  }
}
