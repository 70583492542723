import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from "react-csv";
import api from '../../api';

import { Button } from 'reactstrap';

class ExportContainer extends Component {
  csvLink = createRef();
  state = { data: [] };

  getData = async () => {
    const { name } = this.props;
    try {
      const { data } = await api.exportData(name);
      // click the CSVLink component to trigger the CSV download
      this.setState({ data }, () => this.csvLink.current.link.click());
    } catch (error) {
      console.error(error);
    }
  }

  render(props) {
    const { data } = this.state;
    return(
      <>
        <Button onClick={this.getData} {...props}  >
          {this.props.label}
        </Button>
        <CSVLink data={data} filename={this.props.name} separator={";"} ref={this.csvLink} visible="false" target="_blank" />
      </>
    );
  }
}

ExportContainer.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string
};

ExportContainer.defaultProps = {
  label: 'Download'
};

export default ExportContainer;
