/**
 * Form row input
 *
 * @see https://github.com/shoaibkhan94/reactstrap-formik
 */

import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

import { Col, FormFeedback, FormGroup, Input, Label } from 'reactstrap';

const RowNumberFormatInput = ({
  field: {...fields},
  form: {touched, errors},
  labelcols,
  numtype,
  showuntouched, 
  label,
  labelClass,
  formGroupClass,
  onChange,
  ...props
}) =>
  <FormGroup row className={formGroupClass}>
    <Label xs={labelcols} for={fields.name} className={`label-color text-left ${labelClass}`}>{label}</Label>
    <Col xs={12 - labelcols}>
      <NumberFormat 
        thousandSeparator={'.'} 
        prefix={numtype === 'euro' ? '€ ': ''}
        suffix={numtype === 'perc' ? ' %': ''}
        decimalSeparator={','} 
        customInput={Input}
        {...props} {...fields} invalid={(showuntouched || touched[fields.name]) && !!errors[fields.name]}
      />
      <FormFeedback>{errors[fields.name]}</FormFeedback>
    </Col>
  </FormGroup>

RowNumberFormatInput.propTypes = {
  field: PropTypes.object.isRequired, // da Formik: name
  form: PropTypes.object.isRequired,  // da Formik: touched, errors
  label: PropTypes.string.isRequired, // etichetta
  labelcols: PropTypes.number,        // num colonne per etichetta
  showuntouched: PropTypes.bool,       // mostra errore anche campo non touched (es. numerici)
  formGroupClass: PropTypes.string
};

RowNumberFormatInput.defaultProps = { 
  labelcols: 3,
  formGroupClass: "",
  showuntouched: false,
  numtype: 'euro' // valori: 'euro', 'perc'
 };

export default RowNumberFormatInput;
