import React from 'react';

const UnauthorizedComponent = () =>
  <div>
    Non sei autorizzato ad accedere a questa pagina
  </div>;

UnauthorizedComponent.propTypes = {};

UnauthorizedComponent.defaultProps = {};

export default UnauthorizedComponent;