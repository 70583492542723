import React, { useContext } from 'react';

import { AuthContext } from '../../authentication';

import styles from  './Me.module.css';

const Me = (props) => {
  const {firstName, lastName} = useContext(AuthContext);
  return <><i className="fa fa-user"></i><span className={styles.text}>{`${firstName} ${lastName}`}</span></>
};

export default Me;