import React, { Component } from 'react';
import axios from 'axios';

import api from '../api';

const AuthContext = React.createContext();

const INITIAL_STATE = { isAuth: false, isPM: false, isAreaManager: false, username: '' };

class AuthProvider extends Component {
  state = INITIAL_STATE;

  login = async (username, password) => {
    const { data: { user } } = await api.authenticate(username, password);
    this.setState({ 
      isAuth: true, 
      userid: user.id, 
      username: user.Email, 
      firstName: user.FirstName, 
      lastName: user.LastName, 
      isPM: user.isPM, 
      isAreaManager: user.isAreaManager,
      isFinance: user.isFinance,
      isImportAdmin: user.isImportAdmin,
     });
    axios.defaults.headers.common['Authorization'] = user.token;
  }
  
  logout = () => {
    this.setState(INITIAL_STATE);
    axios.defaults.headers.common['Authorization'] = undefined;
    delete axios.defaults.headers.common['Authorization'];
  }

  render() {
    return (
      <AuthContext.Provider
        value={{...this.state, login: this.login, logout: this.logout}}
      >
        {this.props.children}
      </AuthContext.Provider>
    )
  }
}
const AuthConsumer = AuthContext.Consumer
export { AuthProvider, AuthConsumer, AuthContext }