import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';

import ReactTable from 'react-table';
import NumberFormat from 'react-number-format';
import { Button, Row, Col, FormGroup, Label } from 'reactstrap';
import ToggleButton from 'react-toggle-button'
import FrontendComponentsMultipleSelection from './projects-multiple-selection';
import { PROJECT, PROJECT_ADD } from '../../navigation/routes';
import { getStatusDesc, chargedColor, PLANNED_COLORS } from '../../helpers';
import _ from "lodash";
import 'react-table/react-table.css';
import styles from  './Projects.module.css';

const headerStyle = {fontWeight: 'bold'};

const makeLink = row => `${PROJECT}/${row.value.ProjectId}`;

const plannedCell = row => (
  <div className="d-flex justify-content-between">
    <div
      style={{ color: PLANNED_COLORS[row.original.PlannedProgress], fontSize: 'larger', width: '20px' }}
    >
      &#x25A0;
    </div>
    <div>{row.value}</div>
  </div>
);

const chargedCell = row => (
  <div className="d-flex justify-content-between">
    <div
      style={{ color: chargedColor(row.original.ChargedProgress), fontSize: 'larger', width: '20px' }}
    >
      &#x25A0;
    </div>
    <div>{row.value}</div>
  </div>
);

const numberCell = row => <NumberFormat thousandSeparator={'.'} prefix={'€ '} decimalSeparator={','} value={row.value} displayType={'text'} disabled />

const leftAlign = row => <div title={row.value} style={{textAlign: 'left'}}>{row.value}</div>

const lastUpdateMessage = data => `Last Updated: ${data.substring(8)}-${data.substring(5, 7)}-${data.substring(0, 4)}`

const ProjectComponent = ({ projects, lastUpdate, showAll, toggleShow, showArea, toggleShowArea, isAreaManager }) => {
  const [flag, setFlag] = React.useState();
  const [filtredTableData, setFiltredTableData] = React.useState();
  const [selectedData, setSelectedData] = React.useState();
  const [selectedStatus, setSelectedStatus] = React.useState();
  const [selectedClient, setSelectedClient] = React.useState();
  const [selectedCode, setSelectedCode] = React.useState();
  const [selectedName, setSelectedName] = React.useState();
  const [selectedManager, setSelectedManager] = React.useState();
  const [statusList, setStatusList] = React.useState();
  const [clientsList, setClientsList] = React.useState();
  const [codesList, setCodesList] = React.useState();
  const [projectNameList, setProjectNameList] = React.useState();
  const [managerLastNameList, setManagerLastNameList] = React.useState();

  React.useEffect(() => {
    let findMinArr;
    if(flag==='CdgProjectStatus'){
      findMinArr = selectedStatus;
    }
    if(flag==='CdgProjectClientName'){
      findMinArr = selectedClient;
    }
    if(flag==='CdgProjectCodeExternalReferenceId'){
      findMinArr = selectedCode;
    }
    if(flag==='CdgProjectName'){
      findMinArr = selectedName;
    }
    if(flag==='ManagerLastName'){
      findMinArr = selectedManager;
    }
    if (!findMinArr) {
      const minValue = _.min([
        selectedStatus?.length,
        selectedClient?.length,
        selectedCode?.length,
        selectedName?.length,
        selectedManager?.length,
      ]);
      findMinArr = [
        selectedStatus,
        selectedClient,
        selectedCode,
        selectedName,
        selectedManager,
      ].find((el, id) => {
        if (el?.length === minValue) {
          switch (id) {
            case 0:
              setFlag("CdgProjectStatus");
              break;
            case 1:
              setFlag("CdgProjectClientName");
              break;
            case 2:
              setFlag("CdgProjectCodeExternalReferenceId");
              break;
            case 3:
              setFlag("CdgProjectName");
              break;
            default:
              setFlag("ManagerLastName");
              break;
          }
        }
        return el?.length === minValue;
      });
    }
    setSelectedData(findMinArr)
    if(!selectedData || selectedData.length === projects.length){
      setFiltredTableData([...projects]);
    }else {
      var data = [...projects];
      var filtred = [];
      if(data && data.length>0){
        data.map(d => {
          const find = selectedData.find(s => s === d[flag]);
          if(find){
            filtred.push(d);
          }
        }) 
      }
      setFiltredTableData( [...filtred]);
    }
  }, [projects, selectedData, selectedStatus, selectedClient, selectedCode, selectedName, flag, selectedManager]);


React.useEffect(() => {
  if(selectedData && selectedData.length > 0){
    if(flag==='CdgProjectStatus'){
      const optionsStatus = filtredTableData.map((d) => ({
        id: d.CdgProjectStatus,
        value: d.CdgProjectStatus==='C'? 'Closed' : 'Active',
      }));
      setStatusList(optionsStatus);
    }
    if(flag==='CdgProjectClientName'){
      const optionsClients = filtredTableData.map((d) => ({
        id: d.CdgProjectClientName,
        value: d.CdgProjectClientName,
      }));
      setClientsList(optionsClients);
    }
    if(flag==='CdgProjectCodeExternalReferenceId'){
      const optionsCodes = filtredTableData.map((d) => ({
        id: d.CdgProjectCodeExternalReferenceId,
        value: d.CdgProjectCodeExternalReferenceId,
      }));
      setCodesList(optionsCodes);
    }
    if(flag==='CdgProjectName'){
      const optionsProjectName = filtredTableData.map((d) => ({
        id: d.CdgProjectName,
        value: d.CdgProjectName,
      }));
      setProjectNameList(optionsProjectName);
    }
    if(flag==='ManagerLastName'){
      const optionsManagerLastName = filtredTableData.map((d) => ({
        id: d.CdgManagerLastName,
        value: d.CdgManagerLastName,
      }));
      setManagerLastNameList(optionsManagerLastName);
    }
  }else {
    if(projects && projects.length > 0) {
      getAllOptions();
    }
  }
}, [flag]);

const getAllOptions = () =>{
    const optionsStatus = projects.map((d) => ({
      id: d.CdgProjectStatus,
      value: getStatusDesc(d.CdgProjectStatus),
    }));
    setStatusList(optionsStatus);
    const optionsClients = projects.map((d) => ({
      id: d.CdgProjectClientName,
      value: d.CdgProjectClientName,
    }));
    setClientsList(optionsClients);
    const optionsCodes = projects.map((d) => ({
      id: d.CdgProjectCodeExternalReferenceId,
      value: d.CdgProjectCodeExternalReferenceId,
    }));
    setCodesList(optionsCodes);
    const optionsProjectName = projects.map((d) => ({
      id: d.CdgProjectName,
      value: d.CdgProjectName,
    }));
    setProjectNameList(optionsProjectName);
    const optionsManagerLastName = projects.map((d) => ({
      id: d.ManagerLastName,
      value: d.ManagerLastName,
    }));
    setManagerLastNameList(optionsManagerLastName);
}

  React.useEffect(() => {
    if(projects && projects.length > 0) {
      getAllOptions()
    } else {
      setStatusList([]);
      setClientsList([]);
      setCodesList([]);
      setProjectNameList([]);
      setManagerLastNameList([]);
    }
  }, [projects]);
  
  const columnDefs = [
    {Header: 'Status', accessor: 'CdgProjectStatus', minWidth: 60,Filter: <FrontendComponentsMultipleSelection  data={statusList} setFilter={(data)=>{setFiltredTableData([]);setSelectedStatus(data);}} placeholder="Filter Projects" setFlag={setFlag} value={'CdgProjectStatus'} setSelectedData={setSelectedStatus}/>,
    filter: 'includes', Cell: row => getStatusDesc(row.value)},
    {Header: 'Client', accessor: 'CdgProjectClientName',minWidth: 140,Filter: <FrontendComponentsMultipleSelection  data={clientsList} setFilter={(data)=>{setFiltredTableData([]);setSelectedClient(data);}} placeholder="Filter Projects" setFlag={setFlag} value={'CdgProjectClientName'} setSelectedData={setSelectedClient}/>,
    filter: 'includes', Cell: row => leftAlign(row)},
    {Header: 'Code', accessor: 'CdgProjectCodeExternalReferenceId',Filter: <FrontendComponentsMultipleSelection  data={codesList} setFilter={(data)=>{setFiltredTableData([]);setSelectedCode(data);}} placeholder="Filter Projects" setFlag={setFlag} value={'CdgProjectCodeExternalReferenceId'} setSelectedData={setSelectedCode}/>,
    filter: 'includes', minWidth: 110},
    {Header: 'Project Name', accessor: 'CdgProjectName',Filter: <FrontendComponentsMultipleSelection  data={projectNameList} setFilter={(data)=>{setFiltredTableData([]);setSelectedName(data);}} placeholder="Filter Projects"  setFlag={setFlag} value={'CdgProjectName'} setSelectedData={setSelectedName}/>,
    filter: 'includes', minWidth: 150, Cell: row => leftAlign(row)},
    {Header: 'Manager', accessor: 'ManagerLastName', minWidth: 80,Filter: <FrontendComponentsMultipleSelection  data={managerLastNameList} setFilter={(data)=>{setFiltredTableData([]);setSelectedManager(data);}} placeholder="Filter Projects"  setFlag={setFlag} value={'ManagerLastName'} setSelectedData={setSelectedManager}/>,
    filter: 'includes', Cell: row => leftAlign(row)},
    {Header: 'Start Date', accessor: 'CdgProjectStartDate', minWidth: 80,filterable: false, Cell: row => moment(row.value).format('DD-MM-YYYY')},
    {Header: 'End Date', accessor: 'CdgProjectEndDate', minWidth: 80,filterable: false, Cell: row => moment(row.value).format('DD-MM-YYYY')},
    {Header: 'Project [€]', accessor: 'CdgProjectSellingPrice', minWidth: 120,filterable: false, Cell: row => numberCell(row)},
    {Header: 'Planned [€]', accessor: 'Planned', minWidth: 130,filterable: false, Cell: row => plannedCell(row) },
    {Header: 'Charged [€]', accessor: 'Charged', minWidth: 130,filterable: false, Cell: row => chargedCell(row)},
    {Header: 'Backlog [€]', accessor: 'CdgProjectBacklog',filterable: false,minWidth: 90/*, Cell: row => numberCell(row)*/},
    {Header: 'WIP [€]', accessor: 'CdgProjectPlannedWIPRevenues',filterable: false, minWidth: 90, Cell: row => numberCell(row)},
    {Header: '', accessor: '', filterable: false, sortable: false, minWidth: 40, Cell: row => <Link to={makeLink(row)}><i className="fa fa-edit fa-lg"></i></Link>},
  ];
  
  const columns = columnDefs.map(col => ({...col, headerStyle }));
  
  if ( !filtredTableData) return null;
  return (
    <>
      <Row className={styles.title}>
        <Col md={2}>
          <h3 className={styles.header}>
            <i className="fa fa-tasks"></i>
            <span className={styles.text}>My Projects</span>
          </h3>
        </Col>
        <Col md={2}>
          <div className={styles.lastUpdate}>{lastUpdateMessage(lastUpdate)}</div>
        </Col>
        <Col md={3} className={styles.showAll} >
          {isAreaManager && <FormGroup row>
            <Label for="showarea" xs={7} >Show Area Projects</Label>
            <Col xs={5} className={styles.showToggle} >
              <ToggleButton
                name="showArea"
                value={showArea}
                onToggle={toggleShowArea}
              />
            </Col>
          </FormGroup>}
        </Col>
        <Col md={3} className={styles.showAll} >
          <FormGroup row>
            <Label for="showall" xs={7} >Show Not Active</Label>
            <Col xs={5} className={styles.showToggle} >
              <ToggleButton
                name="showAll"
                value={showAll}
                onToggle={toggleShow}
              />
            </Col>
          </FormGroup>
        </Col>
          <Col>
            <Link to={`${PROJECT_ADD}`}><Button color="success" >Add Project</Button></Link>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
        <ReactTable
          data={filtredTableData}
          columns={columns}
          defaultPageSize={10}
          className="-striped -highlight"
          filterable={true}
        />
        </Col>
      </Row>
    </>
  );
}


ProjectComponent.propTypes = {
  projects: PropTypes.array,
  lastUpdate: PropTypes.string,
  showAll: PropTypes.bool,
  toggleShow: PropTypes.func.isRequired,
  isAreaManager: PropTypes.bool,
  showArea: PropTypes.bool,
  toggleShowArea: PropTypes.func.isRequired
};
  
ProjectComponent.defaultProps = {
  projects: [],
  lastUpdate: '',
  showAll: false,
  isAreaManager: false,
  showArea: false
};
  
export default ProjectComponent;