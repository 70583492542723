import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MyAllocationComponent from "./MyAllocationComponent";
import { Container } from "reactstrap";
import Loader from "../Loader";
import { getAllocations } from "../../reducers";
import moment from 'moment';

const excludeKeys = ['TargetDays','TotalChargedDays','TotalPlanned','PM','LastName','FirstName', 'CurrentMonth','ChargedPerc','PercPlanned','CdgProjectCodeName','CdgProjectCodeExternalReferenceId']

function MyAllocationsContainer(props) {
  React.useEffect(() => {
    const { getAllocations } = props;
    getAllocations();
  }, []);
  const columns = [
    {
      Header: "Code",
      accessor: "CdgProjectCodeExternalReferenceId",
      fixed: "left",
      minWidth: 120,
    },
    {
      Header: "Project Name",
      accessor: "CdgProjectCodeName",
      fixed: "left",
      minWidth: 200,
      maxWidth: 300,
    },
    {
      Header: "Manager",
      accessor: "PM",
      fixed: "left",
      minWidth: 100,
      maxWidth: 300,
    },
  ];
  const getColumns = () => {
    if(allocations.data.length>0){
      const monthKeys = Object.keys(allocations.data[0])
      const filterdKeys = monthKeys.filter(key=>key.substring(0, 7)==='Planned')
      const currentYear = parseInt(allocations.data[0].CurrentMonth.substring(2))
      const currentMonth = parseInt(allocations.data[0].CurrentMonth.substring(0,1))
      let year = currentYear-1;
      filterdKeys.forEach((el, id)=>{
          let date;
          let incrementId = (id+1)%12;
          if(incrementId===0) incrementId=12;
          if(incrementId===1){
              year++;
          }
          if(incrementId<10){
              date = moment(year+'-0'+incrementId).format('MMM-YY');
          } else {
              date = moment(year+'-'+incrementId).format('MMM-YY');
          }
          const headerStyle =(currentMonth===incrementId && year===currentYear)? {
            backgroundColor:'#FFFF8F',
          }:{}
          columns.push({
              Header: date,
              headerStyle: headerStyle,
              accessor: el,
              minWidth: 80,
          })
      })
    }
    return columns;
  }

  const getTableAllocationsData = () => {
    const tableData = [...allocations.data];
    const total = {CdgProjectCodeExternalReferenceId: 'TOTAL'};
    if(tableData && tableData.length>0){
      const keys = Object.keys(tableData[0]).filter(f=>!excludeKeys.includes(f))
      
      tableData.forEach(element=>{
        keys.forEach(key=>{
          if(total[key]) total[key] += element[key]
          else {
            total[key] = 0
            total[key] += element[key]
          }
        })
      });
      keys.forEach(key=> total[key] = Number.parseFloat(total[key]).toFixed(2));
    }
    return [total, ...tableData];
  }

  const { allocations } = props;
  if(!allocations) return null;
  return (
    <Container fluid>
      {allocations.fetched && (
        <MyAllocationComponent data={getTableAllocationsData()} columns={getColumns()} />
      )}
      {allocations.errors && allocations.errors.length > 0 && (
        <p>{allocations.errors}</p>
      )}
      <Loader isLoading={allocations.fetching} />
    </Container>
  );
}

MyAllocationsContainer.defaultProps = {
  allocations: [],
};

const mapStateToProps = ({ allocations }) => {
  return {
    allocations,
  };
};

const mapDispatchToProps = {
  getAllocations,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MyAllocationsContainer));
