import React from "react";

import styles from  '../Projects/Projects.module.css';
import { Row, Col, Alert } from "reactstrap";
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import api from "../../api";

import "react-table/react-table.css";
import "react-table-hoc-fixed-columns/lib/styles.css";

const makeZeroNullVall = (data)=>{
  data.forEach(el=>{
    const keys = Object.keys(el)
    keys.forEach(key=>{
      el[key] = el[key] ?? 0
    })
  })
  return data;
}

const ReactTableFixedColumns = withFixedColumns(ReactTable);

function AreaResourcesComponent(props) {
  const [data, setData] = React.useState(props.data);
  const [alert, setAlert] = React.useState({
    messge: "",
    visible: false,
    color: "warning",
  });

  const onAlertDismiss = React.useCallback(() => {
    setAlert({
      messge: "",
      visible: false,
    });
  },[alert]);

  const onAlertOpen = React.useCallback((message, color = "warning") => {
    setAlert({
      messge: message,
      visible: true,
      color,
    });
  },[alert]);

  React.useEffect(() => setData(props.data), [props.data]);
  if (data?.lenght === 0) {
    return null;
  }

  const title = props.isDetail ? `${data[0].Name}` : "People Allocation";

  const save = async ({ workforceId, ProjectId, month, plannedDays }) => {
    const val = parseInt(plannedDays);
    if (isNaN(val) || val < 0) {
      onAlertOpen("Invalid allocation value");
      return;
    }
    const calcYear = month > 11 ? props.curYear + 1 : props.curYear;
    const calcMonth = month > 11 ? month - 12 : month;
    
    // console.log({ workforceId, ProjectId, year: calcYear, month: calcMonth, plannedDays: val });
    try {
      await api.updateResourceAllocation({ workforceId, ProjectId, year: calcYear, month: calcMonth, plannedDays: val });
      props.refetch();
    } catch (error) {
      console.error(error);
      onAlertOpen("Can't update allocation");
    }
  }

  const renderEditable = (cellInfo) => {
    return (
      <div
        style={{ backgroundColor: cellInfo.column.month === props.curMonth ? '#FFFF8F' : '#fafafa', }}
        contentEditable
        suppressContentEditableWarning
        onBlur={e => {
          const newData = [...data];
          newData[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
          const { CdgProjectWorkforceId: workforceId, ProjectId } = cellInfo.original;
          const { month } = cellInfo.column;
          save({ workforceId, ProjectId, month, plannedDays: e.target.innerHTML })
          setData(newData);
        }}
        dangerouslySetInnerHTML={{
          __html: data[cellInfo.index][cellInfo.column.id]
        }}
      />
    );
  }

  return (
    <>
      <Alert
          className="sticky-top pb-2"
          color={alert.color}
          isOpen={alert.visible}
          toggle={onAlertDismiss}
        >
          <h6>{alert.messge}</h6>
      </Alert>
      <Row className={styles.title}>
        <Col md={5}>
          <h3 className={styles.header}>
            <i className="fa fa-users"></i>
            <span className={styles.text}>{`${title} - ${data[0].CurrentMonth}`}</span>
          </h3>
        </Col>
        {props.isDetail && <>
        <Col className='textAllocation' md={2}>
          <span className="childSpan">{`Target Days: ${data[0].TargetDays}`}</span>
        </Col>
        <Col className='textAllocation' md={3}>
          <span className="childSpan">{`Total Charged Days: ${data[0].TotalChargedDays} (${data[0].ChargedPerc} %)`}</span>
        </Col> 
        <Col className='textAllocation' md={2}>
          <span className="childSpan">{`Total Planned: ${data[0].TotalPlannedDays} (${data[0].PercPlanned} %)`}</span>
        </Col>
        </>}
      </Row>
      <Row>
        <Col md={12}>
          <ReactTableFixedColumns
            columns={props.columns.map(col => ({...col, Cell: col.month && props.isDetail ? renderEditable : col.Cell }))}
            data={makeZeroNullVall(data)}
            defaultPageSize={10}
            className="-striped -highlight"
            style={{ width: "100%", overflow: "auto" }}
          />
        </Col>
      </Row>
    </>
  );
}

export default React.memo(AreaResourcesComponent);
