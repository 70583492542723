import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { AuthProvider } from '../../authentication';
import AppRoutes from '../../navigation/AppRoutes';
import Navbar from '../Navbar';

import './App.css';

const AppComponent = () =>
  <div className="App">
    <AuthProvider>
      <Router>
        <>
          <Navbar />
          <AppRoutes />
        </>
      </Router>
    </AuthProvider>
  </div>;

AppComponent.propTypes = {};

AppComponent.defaultProps = {};

export default AppComponent;