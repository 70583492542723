import React from "react";

import styles from  '../Projects/Projects.module.css';
import { Row, Col } from "reactstrap";
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";

import "react-table/react-table.css";
import "react-table-hoc-fixed-columns/lib/styles.css";

const makeZeroNullVall = (data)=>{
  data.forEach(el=>{
    const keys = Object.keys(el)
    keys.forEach(key=>{
      el[key] = el[key] ?? 0
    })
  })
  return data;
}

const ReactTableFixedColumns = withFixedColumns(ReactTable);

function AreaResourcesComponent({ data, columns }) {
  const title = "Area Resources Time";

  return (
    <>
      <Row className={styles.title}>
        <Col md={5}>
          <h3 className={styles.header}>
            <i className="fa fa-users"></i>
            <span className={styles.text}>{`${title} - ${data[0].CurrentMonth}`}</span>
          </h3>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ReactTableFixedColumns
            columns={columns}
            data={makeZeroNullVall(data)}
            defaultPageSize={10}
            className="-striped -highlight"
            style={{ width: "100%", overflow: "auto" }}
          />
        </Col>
      </Row>
    </>
  );
}

export default React.memo(AreaResourcesComponent);
