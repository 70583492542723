import React, { useContext } from 'react';
import { connect } from 'react-redux';
import * as routes from '../../navigation/routes';
import { Container } from 'reactstrap';
import withUser from '../withUser';
import Loader from '../Loader';
import ProjectsComponent from './ProjectsComponent';
import { getProjects, getLastUpdate } from '../../reducers';
import { AuthContext } from '../../authentication';

import {Redirect} from 'react-router-dom';
function ProjectContainer(props) {

  const { isPM, isFinance } = useContext(AuthContext); 

  const [state, setState] = React.useState({
    showAll: false,
    showArea: false
  });

  React.useEffect(()=> {
    const { getProjects, getLastUpdate } = props;
    getProjects();
    getLastUpdate();
  },[])

  const toggleShow = () => setState({ showAll: !state.showAll });

  const toggleShowArea = () => setState({ showArea: !state.showArea });

  const filterProjects = () => {
    const { projects: { data }, userid } = props;
    const { showAll, showArea } = state;
    let filtered = data;
    if (!showAll) filtered = filtered.filter(p => p.CdgProjectStatus === 'A' || p.CdgProjectStatus === 'B'); // solo status Aperto o Renew
    if (!showArea) filtered = filtered.filter(p => p.CdgProjectManagerId === userid); // solo project Manager utente corrente
    return filtered;
  }
    const { projects, isAreaManager, lastUpdate } = props;
    const { showAll, showArea } = state;

    if(!isPM && isFinance) {return <Redirect to={routes.RECONCILIATION}/>  }
    if(!isPM) {return <Redirect to={routes.ALLOCATIONS}/>  }

    return (
      <Container fluid>
        {projects.fetched && lastUpdate.fetched  && <ProjectsComponent {...props} 
          projects={filterProjects()} 
          showAll={showAll} 
          toggleShow={toggleShow}
          isAreaManager={isAreaManager}
          showArea={showArea}
          toggleShowArea={toggleShowArea}
          lastUpdate={lastUpdate.data}
        />}
        {projects.errors && projects.errors.length > 0 && <p>{projects.errors}</p>}
        {lastUpdate.errors && lastUpdate.errors.length > 0 && <p>{lastUpdate.errors}</p>}
        <Loader isLoading={projects.fetching || lastUpdate.fetching} />
      </Container>
    );
  
}

ProjectContainer.propTypes = {};

ProjectContainer.defaultProps = {
};

const mapStateToProps = ({ projects, lastUpdate }) => {
  return {
    projects,
    lastUpdate
  }
};

export default connect(mapStateToProps, { getProjects, getLastUpdate })(withUser(ProjectContainer));