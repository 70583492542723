// https://codesandbox.io/s/github/tannerlinsley/react-table/tree/v6/examples/editable-content?file=/src/index.js
import React from "react";
import { Link } from 'react-router-dom';
import styles from  '../Projects/Projects.module.css';
import { Row, Col } from "reactstrap";
import ReactTable from "react-table";
import { RECONCILE_PROJECT } from '../../navigation/routes';

import "react-table/react-table.css";
import "react-table-hoc-fixed-columns/lib/styles.css";

const makeLink = row => `${RECONCILE_PROJECT}/${row.value}`;

const headerStyle = {fontWeight: 'bold'};
const columnDefs = [
  {Header: 'Code', accessor: 'CdgProjectCodeExternalReferenceId', minWidth: 80, headerStyle},
  {Header: 'Client', accessor: 'CdgProjectClientName', minWidth: 100, headerStyle},
  {Header: 'Name', accessor: 'CdgProjectName', minWidth: 200, headerStyle},
  {Header: 'Manager', accessor: 'Manager', minWidth: 200, headerStyle},
  {
    Header: '',
    accessor: 'id',
    minWidth: 50,
    Cell: row => <Link to={makeLink(row)}><i className="fa fa-edit fa-lg"></i></Link>,
    filterable: false,
    sortable: false,
  },
];


function ProjectsReconciliationComponent(props) {
  if (props.data?.lenght === 0) {
    return null
  }

  return (
    <>
      <Row className={styles.title}>
        <Col md={10}>
          <h3 className={styles.header}>
            <i className="fa fa-tasks"></i>
            <span className={styles.text}>Codes Reconciliation</span>
          </h3>
        </Col>
      </Row>
      <Row>
        <Col md={{offset: 2, size: 8}}>
          <ReactTable
            columns={columnDefs}
            data={props.data}
            defaultPageSize={10}
            className="-striped -highlight"
            style={{ width: "100%", overflow: "auto" }}
            filterable={true}
          />
        </Col>
      </Row>
    </>
  );
}

export default ProjectsReconciliationComponent;
