import React from 'react';
import PropTypes from 'prop-types';

import { Col, FormFeedback, FormGroup, Input, Label } from 'reactstrap';

const RowFileInput = ({
  field: {value, ...fields},
  form: {errors, ...rest},
  onFileLoaded,
  fileEncoding,
  accept,
  labelcols, 
  label,
  labelClass,
  formGroupClass,
  ...props
}) => {
  const handleChangeFile = e => {
    const reader = new FileReader();
    const [file] = e.target.files;
    reader.onload = () => {
      const result = reader.result;
      onFileLoaded({name: file.name, size: file.size, type: file.type, data: result, file: file});
    };
    reader.readAsDataURL(file);
  };

  return(
    <FormGroup row className={formGroupClass}>
      <Label xs={labelcols} for={fields.name} className={`label-color text-left ${labelClass}`}>{label}</Label>
      <Col xs={12 - labelcols}>
        <Input type="file" accept={accept} {...props} {...fields} onChange={handleChangeFile} invalid={Boolean(errors[fields.name])}/>
        {errors[fields.name] ? <FormFeedback>{errors[fields.name]}</FormFeedback> : ''}
      </Col>
    </FormGroup>
  );
};

RowFileInput.propTypes = {
  field: PropTypes.object.isRequired, // da Formik: name
  form: PropTypes.object.isRequired,  // da Formik: touched, errors
  label: PropTypes.string.isRequired, // etichetta
  labelcols: PropTypes.number,        // num colonne per etichetta
  formGroupClass: PropTypes.string,
  onFileLoaded: PropTypes.func.isRequired,
};

RowFileInput.defaultProps = { 
  labelcols: 3,
  formGroupClass: "",
  accept: '.csv, text/csv'
 };

export default RowFileInput;
